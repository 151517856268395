

import { useMemo, useState, useCallback, useEffect } from 'react';
import PrivateHttpClient from '../../../../infrastructure/keraltyApi/privateHttpClient';
// 3RD PARTY
import { useAppDispatch, useAppSelector } from '../../../../adapter/hooks';
import { shutdown, show, setUp } from '../../../../adapter/sanitasChat/sanitasChat';
//Interaction
import { closeSymptomChecker, openBookAppointment } from '../../../../adapter/chat/chatSlice';
//LOGIC
import useAuth from '../../../hooks/useAuth';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import Loader from '../../../components/templates/loader';
// UI
import TabWrapper from './TabWrapper';
import { KeraltyText, Image, KeraltyButton } from '../../atoms';
import EmptyIcon from '../../atoms/emptyIcon';
import { SeeIconCell, KeraltyFilterDate, KeraltyModal } from '../../molecules';
import { Column, Container, Row, SpaceWrapper } from '../../templates';
import BREAKPOINTS from '../../../ui-core/utils/breakpoints';
import styled from '../../../ui-core/styled-components';
import { LabResult } from 'domain/entities/labResult';
import useIsMobile from '../../../hooks/useIsMobile';
import Ellipsis from '../../../assets/images/circle-ellipsis.png';
import Xmark from '../../../assets/images/circle-xmark.png';
import Headset from '../../../assets/images/headset.png';
import CalendarHeart from '../../../assets/images/calendar-heart.png';
import UserDoctor from '../../../assets/images/user-doctor-message.png';
import IconPDF from '../../../assets/images/icono-pdf.png';
import FilterImg from '../../../assets/images/filter.png';
import FilterEs from '../../../assets/images/filter-es.png';
import XRayIcon from '../../../assets/icons/XRay.svg';
import CircleEllipsis from '../../../assets/icons/circle-ellipsis.svg';
import CircleXmark from '../../../assets/icons/circle-xmark.svg';
import FilterClose from '../../../assets/images/filter-close.png';
import FilterRemove from '../../../assets/images/filter-remove.png';
import PaginateBar from 'app/components/atoms/paginateBar';
import moment from 'moment';
import isCordova from 'cordova';
import NoSearchResult from 'app/components/molecules/noSearchResult';
import NoResult from '../../../assets/icons/noResult.svg';
import EmptyLabs from '../../../assets/icons/Empty_labsImaging.svg';
import ImgModal3 from '../../../assets/icons/hand.svg';
import { disableToTN } from 'app/ui-core/utils/disableOptions';
import { useHistory } from 'react-router-dom';
import { userSelectors } from 'adapter/user/userSelectors';
import ROUTES from '../../../ui-core/utils/routes';
import { utcTime } from 'app/ui-core/utils/times';
import useProviderChat from 'app/hooks/useChatProvider';
import ModalInsuranceValidate from 'app/components/molecules/ModalInsuranceValidate';
import { selectPaymentLoading } from 'adapter/payment/paymentSelectors';
import { selectmyHealthError } from 'adapter/myHealth/myHealthSelectors';
import { ReactComponent as CircleExclamation } from 'app/assets/icons/circle-exclamation.svg';
import { ModalErrorMyHealth } from 'app/components/molecules/modalError';

declare global {
	interface Window {
		FB: any;
	}
}

declare global {
	interface Window {
		resolveLocalFileSystemURL: any;
	}
}




let FB = window.FB;

type Props = {
	labData: LabResult[];
	onClickItem: (idx?: number, cellText?: string) => void;
};

const MainContain = styled(Row)`
	display: contents;
	justify-content: space-around;
	align-content: center;
	align-items: center;
	z-index: 1;
	padding-bottom: 10%;

`;

const ContainMap = styled(Row)`
  	display: flex;
	justify-content: space-between;
	background: #FFFFFF;
	box-shadow: 0px 4px 4px rgba(1, 42, 88, 0.25);
	border-radius: 14px;
	width: 90%;
	height: 84px;
  	align-content: center;
	align-items: center !important;
	padding: 10px;
	@media (min-width: ${BREAKPOINTS.minPhoneWidth}) {
		height: max-content;
	}
`;

const Title = styled(KeraltyText)`
	margin: 0;
`;
const ContentButton = styled.div`
	@media  (max-width: 811px){
		width: 100%;
		>div{
			margin: auto
		}
	};
	@media (min-width: 811px) and (max-width: 1238px){
		width: 30%;
	};
`;
const PdfButton = styled(Column)`
	letter-spacing: 1px;
	display: block !important;
  background: #3CA70D;
	border-radius: 29px;
	color: #FFFFFF;
	width: 160px;
	height: 35px;
	text-align: center !important;
	cursor: pointer;
	font-weight: 700;
	font-size: 17px;
	line-height: 35px;
	@media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
		width: 286px;
	};
	@media (max-width: 433px) {
		width: 230px;
	};
	@media (max-width: 803px){
		width: 240px;
		margin: 10px
	};
`;

const ContainInfo = styled(Column)`
	flex-direction: column;
	width: 60%;
	margin: 10px;
	@media (min-width: 1238px){
		width: 63%;
	};
	@media (min-width: 1021px) and (max-width: 1267px){
		width: 45%;
	};
	@media (max-width: 803px){
		width: 100%;
	};
`;

const ContainImg = styled(Column)`
	width: 20px;
	height: 60px;
	@media (min-width: 600px) and (max-width: 1028px){
		margin-right: 20px;
		margin-left: -10px;

	};
	@media (max-width: 600px){
		margin-right: 10px;
		margin-left: 21px;
	};
	@media (max-width: 500px){
		margin: 6px 10px 0 15px
	};
`;

const Content = styled.div`
	display: flex;
	margin-left: -10%;
	@media (min-width: 600px) and (max-width: 1023px){
		margin-left: 0;
	}
`;
const NewContainer = styled(TabWrapper)`
	background: red !important;
	whith: 100%;
	heigth: 100%;

`;
const CardTablet = styled(Row)`
	display: flex;
	flex-direction: initial;
	margin-block: 3px;
	@media (max-width: 1308px) {
		flex-direction: column;
	}
`;
const Icon = styled(Row)`
	color: #48AC1C;
  	width: 15px !important;
  	height: 15px !important;
  	margin: 0;
	@media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
		color: #5B5C5B;
	}
`;

const IconLab = styled(Row)`
	color: #48AC1C;
  	width: 40px !important;
  	height: 40px !important;
	@media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
		width: 30px !important;
  	height: 30px !important;
	}
`;

const ContainHelp = styled(Row)`
	//color: #055293;
  	width: 235px !important;
  	height: auto !important;
  	background: rgba(255, 255, 255, 0.9);
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
	backdrop-filter: blur(20px);
	border-radius: 10px;
	position: absolute;
	margin-top: 2.5%;
	padding: 10px;
	align-content: space-between;
	@media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
		margin-top: 7% !important;
	}
`;

const styleButton = {
	color: '#3CA70D',
	marginRight: 60,
	marginLeft: 52,
	marginTop: 10,
	paddingTop: 0,
	paddingBottom: 0,
	backgroundColor: '#FFFFFF',
	borderRadius: 40,
	border: 'none',
	borderColor: "#FFFFFF",
	fontSize: 16,
	fontWeight: 'bold',
}

const styleDiv = {
	marginTop: 0,
	paddingTop: 0,
	paddingBottom: 10,
	marginLeft: 0,
	marginRight: 0,
	flex: 1,
	align: "center",
	justifyContent: 'center',
	alignSelf: 'center',
}

const styleText = {
	textAlign: "center",
	marginRight: 7,
	marginTop: 15,
	color: "#5b5c5b",
	fontWeight: 400,
}

const styleNoResultMobile = {
	marginInline: 'none',
	marginTop: '35%',
}

const styleNoResult = {
	marginInline: 100,
}

const ContentDiv = styled.div`
	background: #3CA70D;
	border-radius: 29px;
	height:30px;
	@media (max-width: 803px){
	background: #3CA70D;
	border-radius: 29px;
	height:25px;
	};
`;
const ContainModal = styled(Column)`
  position: fixed;
  display: flex;
  justify-content: center;
  left: 0px;
  right: 0px;
  bottom: 0px;
  height: 100%;
  overflow: auto;
  z-index: 99;
  background-color: #00000058;
  width: 100%;
  padding: 0;
  @media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {

	}
`;
const Modal = styled(Column) <{ isMobile: boolean }>`
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 20px;
  padding: 10px;
  width: ${({ isMobile }) => isMobile ? '80%' : 'fit-content'};
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 	0%;
  margin-top: ${({ isMobile }) => isMobile ? '60%' : '15%'};
  @media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {

	}
`;
const ContainerCancelButton = styled(Column)`
	display: flex;
	text-align: center;
	justify-content: center;
`;
const ContainerFilterButton = styled(Column)`
	display: flex;
	text-align: center;
	justify-content: center;
	align-content: center;
	margin: 0;
`;


const Button = styled(KeraltyButton)`
	letter-spacing: 1px;
	align-self: center;
	margin-top: 10px;
	@media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
		padding: 0px;
	}
`;

const LabTable: React.FC<Props> = ({ labData, onClickItem }) => {
	const { t } = useTranslation();
	const [isHelp, setIsHelp] = useState(true);
	const [filter, setFilter] = useState(true);
	const { user } = useAuth();
	const dispatch = useAppDispatch();
	const isMobile = useIsMobile();
	const sanitasChatState = useAppSelector((state) => state.sanitasChat);
	const { sanitasState } = sanitasChatState;
	const isSCOpen = useAppSelector(({ chat }) => chat.currentInstance === 'symptomChecker');
	const endpoint = 'ecw';
	const [cleanInput, setCleanInput] = useState(false);
	const [currPage, setCurrPage] = useState(1);
	const [dataObject, setDataObject] = useState<{ from?: string; to?: string }>({});
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [isFiltered, setIsFiltered] = useState(false);
	//const [showModal, setShowModal] = useState<boolean | null>(null);
	const [openPopUp, setOpenPopUp] = useState(false);
	const [visible, setVisible] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const isLoadingTwo = useAppSelector(selectPaymentLoading);
	const [modal, setModal] = useState(false);
	const { push } = useHistory();
	const isActiveCoverage = useAppSelector(userSelectors.selectActiveCoverage);
	const { MAIN, GET_CARE } = ROUTES;
	const [itemToDownload, setItemToDownload] = useState<any>();
	const { authUid } = useAppSelector(state => state.user)
	const { handleChatWithDoc, currentChatStatus, closeModalChatStatus, openChat } = useProviderChat();
	const [closeModalError, setCloseModalError] = useState(false);
	const error = useAppSelector(selectmyHealthError);
	useEffect(() => {
		if (error) setCloseModalError(error)
	}, [error])


	const data = useMemo(
		() =>
			labData.map((labItem, idx) => ({
				...labItem,
				reason: <SeeIconCell idx={idx} cellText={labItem.reason} onClick={onClickItem} />,
				icon: <SeeIconCell idx={idx} cellText={''} hasIcon onClick={onClickItem} />,
			})),
		[labData, onClickItem]
	);


	const PopupError = () => {
		return (
			<div>
				<KeraltyModal size="extraLong"  >
					<Row noMargin>
						<SpaceWrapper mb={2} mt={3}>
							<Container centered={'x'} noPadding noMargin>
								<KeraltyText color='#0069A7' type="info" align="center" fontSize='20px' fontFamily='Nova' style={{ lineHeight: 1.1 }}>{t('popupPdhError')}</KeraltyText>
							</Container>
						</SpaceWrapper>
					</Row>
					<Row noMargin style={{ display: 'flex', justifyContent: 'center', marginBottom: 6 }}>
						<ContainerFilterButton sm={4}>
							<Button smFull type={'submit'}
								onClick={(e) => {
									e.preventDefault();
									setOpenPopUp(false);
								}}
							>
								{t('ok')}
							</Button>
						</ContainerFilterButton>
					</Row>
				</KeraltyModal>
			</div>
		);
	}


	useMemo(() => {
		//method in charge of cleaning form
		if (filter) setDataObject({})
	}, [filter]);

	const onChangeDate = useCallback((type, data) => {
		type && setDataObject(data);
		type && setVisible(true);
		setIsModalVisible(false);
		setIsFiltered(true)
		setCurrPage(1)
	}, []);

	const dataToDisplay = useMemo(() => {
		//function in charge of filtering data by date range
		if (!dataObject.to || !dataObject.from) return data;
		const fromDate = moment(dataObject.from, 'MM/DD/YYYY');
		const toDate = moment(dataObject.to, 'MM/DD/YYYY');
		return data.filter((appointment) => {
			const currentDate = moment(appointment.orderDate, 'YYYY-MM-DD');
			return currentDate.isBetween(fromDate, toDate, undefined, '[]');
		});
	}, [dataObject, data]);

	const newData = useMemo(() => {
		const indexLastPage = currPage * 5;
		const indexFirstPage = indexLastPage - 5;

		return dataToDisplay.slice(indexFirstPage, indexLastPage)
	}, [currPage, dataToDisplay]);

	const lastPage = Math.ceil(dataToDisplay.length / 5);

	const options = () => {
		setIsHelp(!isHelp)
	}


	const triggerPatientSupport = useCallback(async () => {
		setIsHelp(!isHelp)
		if (isSCOpen) dispatch(closeSymptomChecker());
		if (sanitasState.isShow && sanitasState.isSetted) {
			await dispatch(shutdown());
		}
		await setTimeout(async () => {
			await dispatch(setUp({
				actionColor: "#7cbc98",
				backgroundColor: "#7cbc98",
				name: `${user.displayName}`,
				email: `${user.email}`,
				state: `${user.state}`,
				currentTimeZone: `${utcTime()}`,
				authUid: `${authUid}`,
				sanitasAccountNumber: `${user.idEcw}`,
				gender: `${user.sex}`,
				birthday: `${user.birthdate}`,
				customAttributes: { cType: 'patientSupport' },
			}));
			await dispatch(show());
		}, 500);
	}, [dispatch, sanitasState, user, isSCOpen]);
	//Download PDF
	const downloadPdf = (dat) => {
		const body = {
			"patientId": dat.patientId,
			"reportId": dat.reportId,
			"dob": user.birthdate,
			"facilityInfo": dat.facilityInfo,
			"providerInfo": dat.providerInfo,
			"itemName": dat.itemName,
			"clinicalCenter": dat.clinicalCenter,
			"notes": dat.notes,
			"language": i18n.language,
		};
		const headers = {
			"Content-Type": "application/json",
			"recaptcha": "03AGdBq26Jdo7Qob1O9m4xcCHdLoV9uS75dIW4neNgfDkC1turMf_9I2OGZXinG8Ys7sbkfqYU_MXFT1QVoIxOCIpValAOqcvYjGKRmQ-NDWE-imfE3x9pTOi2WnsBqjg75vJDdAQy5Jo9wr-J811F6lNZOfoLSBvf-NM4rjuSrDOILkYY0vxDH3XOJysmXes1jO1zDXbocLMUNRdTx295t2RCAFMLuj_i7zCcmi-S5iWhMc64M32sZcHzgY8xxJci0qRjoFgW7uGzUCQ6VURO8_Ko4OJQE7ea1xSPNa97p68i3U0QcxV1tcOJsiXczeMo3kzu9TaRozdDz3TU99SmSslUnpY34WsK37CNiBz9HnaHJuBOocbEpojjCMH2xk51J170nxmkGnYNpqg-pEAPlhB9PT5TZJL0764bou9Ii5LTkf42O7KSBvUu4pAXzpEa9YcKyaIRoTk52UhJomlqVqJLX9ZZJAhgM7PbwScc040VuqI9FYcDsvLlFP__70Te80DAJ1B948cnfuYAU9LtufkBg7cHmJm96k6mOh12KF5F-FzeWAXaV2EAzHPU3A8fZ843FkVVdHtZ9B0fDp1t6EZBAKoa-tNPAC5WKParOZmrvabu7rQX3j8vCRJayL_ZufbaNLYTRxnSOzstDNP1xTFegEd3McafmOW-P2YoxuU3G58gbozeEnNPSzBOiO1i7vsOL25BKmIbyqQOeTDwveZy9J8uUeXti8GZMPSyCUD5uqw-0yaxuDG3qZUl9Irayp6lt79ocKyFnRcsvrPvToV2mhbLGEl256XLodE3emFEZ2f9gT0tS8s6v1rxQTzHAwgx6YTCdhTsjy1sE5M9M3-qpprEqJEZK0iYqw9ew2HB1lckyJbRNBZoPIKYFUb8pAgiKCwdpudehE7lK4zC--_G6CJUoxNrI8MEir5E4zHPFEol3oNXKIH5zH7CnWJ1TCqvu93ejhZDl5CjwZHSK56Ed_MazzTv17OOj0rnQo8vZBXpj4x7RhXSNz3REwpKhaJFCeIaJ-Fz56Zb1njNJ2LHWjkxzWU5ekOufBeWYIp46cczltvjt-nNYhH6GtNH99VURJdW1vkB4ggTtFk5_DGR_SnwF-saBI-rrQFw623tKlo5TWci869XxjBMeYGnjBS2PIubVYd1UmRTFdNnEuz805cBcM6PPpIH3ziWrdZTt6OfGnpLgX8p8yMxNoatyGAnOaS64yv"
		};
		return PrivateHttpClient.post(`${endpoint}/patient/lab-di-details`, body, { headers: headers })
			.then((res) => {
				if (res) {
					if (isCordova) {
						var dataBase64 = res;
						console.log("======> Start process...")
						var contentType = "application/pdf";
						var folderpath = window.cordova.file.dataDirectory
						var filename = 'document' + body.patientId;
						savebase64AsPDF(folderpath, filename, dataBase64, contentType);
					} else {
						const linkSource = `data:application/pdf;base64,${res}`;
						let downloadLink = document.createElement("a");
						downloadLink.href = linkSource;
						const dateBD = dat.orderDate;
						const dateFormat = moment(dateBD).format('MM/DD/YYYY');
						if (dat.type === "LAB") {
							downloadLink.download = `${t('myHealth.laboratory')} ${dat.itemName} ${dateFormat}.pdf`;
							downloadLink.click();
						} else {
							downloadLink.download = `${t('myHealth.textDiagnostic')} ${dat.itemName} ${dateFormat}.pdf`;
							downloadLink.click();
						}

					}
				}
			}
			).catch((e) => {

				setOpenPopUp(true)
			})
			;
	}
	const saveArch = async (res) => {
		var contentType = "application/pdf";
		var DataBlob = b64toBlob(res, contentType);

		const fileVentana = await window.showSaveFilePicker({
			suggestedName: 'doc.pdf',
			types: [{
				description: '',
				accept: {
					'application/pdf': ['.pdf'],
				},
			}],
		});

		const fileStream = await fileVentana.createWritable();

		//guardar archivo
		await fileStream.write(DataBlob); //Blob!!!
		await fileStream.close();

	}
	const b64toBlob = (b64Data, contentType, sliceSize = 512) => {
		contentType = contentType || '';

		var byteCharacters = atob(b64Data);
		var byteArrays: any = [];

		for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
			var slice = byteCharacters.slice(offset, offset + sliceSize);

			var byteNumbers = new Array(slice.length);
			for (var i = 0; i < slice.length; i++) {
				byteNumbers[i] = slice.charCodeAt(i);
			}

			var byteArray = new Uint8Array(byteNumbers);

			byteArrays.push(byteArray);
		}

		var blob = new Blob(byteArrays, { type: contentType });
		return blob;
	}

	const savebase64AsPDF = (folderpath, filename, content, contentType) => {
		var DataBlob = b64toBlob(content, contentType);
		window.resolveLocalFileSystemURL(folderpath, function (dir) {
			dir.getFile(filename, { create: true }, function (file) {
				file.createWriter(function (fileWriter) {
					fileWriter.write(DataBlob);
					var finalPath = folderpath + filename;
					window.cordova.plugins.fileOpener2.open(finalPath, 'application/pdf'
						,
						{
							error: function (e) {
								console.log('Error status: ' + e.status + ' - Error message: ' + e.message);
							},
							success: function () {
								console.log('file opened successfully');
							}
						}
					);

				}, function () {
					console.log("err");
				});
			},);
		});
	}

	//Componente de accesos rapidos
	const MainHelp = () => {
		return (
			<div style={{ display: 'flex', zIndex: 3, justifyContent: 'end', }}>
				<img style={{ cursor: 'pointer' }} onClick={() => options()} src={isHelp ? CircleEllipsis : CircleXmark} alt="" />
				{!isHelp ?
					!isMobile ?
						<ContainHelp>
							{
								<div onClick={() => handleChatWithDoc()} style={{ display: 'flex', marginTop: 10, cursor: 'pointer' }}>
									<div style={{ margin: '10px', marginRight: 0 }}>
										<img style={{ marginRight: '10px' }} src={UserDoctor} alt="" />
									</div>
									<KeraltyText style={{ textAlign: 'start' }} fontSize={'14px'} bold={true} type={'subTitle'}>{t('button.chatWithaDoctorC')}</KeraltyText>
								</div>
							}
							<div onClick={() => dispatch(openBookAppointment())} style={{ margin: '10px', cursor: 'pointer' }}>
								<img style={{ marginRight: '13px' }} src={CalendarHeart} alt="" />
								<KeraltyText fontSize={'14px'} bold={true} type={'subTitle'}>{t('getCare.bookAppointmentC')}</KeraltyText>
							</div>
							<div onClick={() => openChat({ queue: 'support' })} style={{ margin: '10px', cursor: 'pointer' }}>
								<img style={{ marginRight: '10px' }} src={Headset} alt="" />
								<KeraltyText fontSize={'14px'} bold={true} type={'subTitle'}>{t('myHealth.supportChatC')}</KeraltyText>
							</div>
						</ContainHelp>
						:
						<>
							{
								!isHelp &&
								<KeraltyModal size="long" scroll='initial' onClickContainer={options}>
									{
										<div onClick={() => handleChatWithDoc()} style={{ display: 'flex', marginTop: 10, cursor: 'pointer' }}>
											<div style={{ margin: '10px', marginRight: 0 }}>
												<img style={{ marginRight: '5px' }} src={UserDoctor} alt="" />
											</div>
											<KeraltyText style={{ textAlign: 'start' }} fontSize={'14px'} bold={true} type={'subTitle'}>{t('button.chatWithaDoctorC')}</KeraltyText>
										</div>
									}
									<div onClick={() => dispatch(openBookAppointment())} style={{ margin: '10px', cursor: 'pointer', display: 'flex', marginBlock: 20 }}>
										<img style={{ marginRight: '13px' }} src={CalendarHeart} alt="" />
										<KeraltyText fontSize={'14px'} bold={true} type={'subTitle'}>{t('getCare.bookAppointmentC')}</KeraltyText>
									</div>
									<div onClick={() => openChat({ queue: 'support' })} style={{ margin: '10px', cursor: 'pointer', display: 'flex', marginBottom: 30 }}>
										<img style={{ marginRight: '10px' }} src={Headset} alt="" />
										<KeraltyText fontSize={'14px'} bold={true} type={'subTitle'}>{t('myHealth.supportChatC')}</KeraltyText>
									</div>
								</KeraltyModal>}
						</>
					: ''
				}
			</div>
		)
	}
	//Componente de filtros
	const MainFilter = () => {
		return (
			<div>
				{i18n.language == 'en' ?
					<img onClick={() => { setFilter(!filter); setIsModalVisible(filter); setCleanInput(!cleanInput) }} src={isMobile ? FilterImg : filter ? FilterImg : ''} alt="" style={{ cursor: 'pointer' }} />
					:
					<img onClick={() => { setFilter(!filter); setIsModalVisible(filter); setCleanInput(!cleanInput) }} src={isMobile ? FilterEs : filter ? FilterEs : ''} alt="" style={{ cursor: 'pointer' }} />
				}
			</div>
		);
	};

	const DownloadPdfModal = () => (
		<ContainModal>
			<Modal isMobile={isMobile}>
				<div style={{ padding: '10px' }}>
					<img style={{ background: '#3CA70D', borderRadius: '50%' }} src={ImgModal3} alt="" />
				</div>

				{isMobile
					? (
						<div style={{ alignSelf: 'center', textAlign: 'center', padding: '5px' }}>
							<KeraltyText color='#0069A7' type="info" align="center" fontSize='20px' fontFamily='Nova'>
								{t('myHealth.saved')}
							</KeraltyText>
						</div>
					)
					: (
						<div style={{ alignSelf: 'center', textAlign: 'center', padding: '5px', width: '60%' }}>
							<KeraltyText color='#0069A7' type="info" align="center" fontSize='20px' fontFamily='Nova'>
								{t('myHealth.saved')}
							</KeraltyText>
						</div>
					)
				}

				<div style={{ alignSelf: 'center', textAlign: 'center', padding: '5px' }}>
					<KeraltyText color='#0069A7' type="info" align="center" fontSize='20px' fontFamily='Nova' width='20px' >
						{t('myHealth.want')}
					</KeraltyText>
				</div>

				{isMobile
					? (
						<Row>
							<ContainerFilterButton lg={12} md={6} sm={6} >
								<Button smFull type={'submit'}
									onClick={() => {
										downloadPdf(itemToDownload)
										setModal(false)
									}}
								>
									{t('myHealth.yes')}
								</Button>
							</ContainerFilterButton>

							<ContainerCancelButton lg={0} md={6} sm={6}>
								<Button
									outline
									smFull
									type={'text'}
									onClick={() => {
										setModal(false)
									}}
								>
									{t('myHealth.no')}
								</Button>
							</ContainerCancelButton>
						</Row>
					)
					: (
						<div style={{ display: 'grid', placeItems: 'center' }}>
							<Row>
								<ContainerFilterButton>
									<Button smFull type={'submit'}
										onClick={() => {
											downloadPdf(itemToDownload);
											setModal(false)
										}}
									>
										{t('myHealth.yes')}
									</Button>
								</ContainerFilterButton>

								<ContainerCancelButton>
									<Button
										outline
										smFull
										type={'text'}
										onClick={() => {
											setModal(false)
										}}
									>
										{t('myHealth.no')}
									</Button>
								</ContainerCancelButton>
							</Row>
						</div>
					)
				}
			</Modal>
		</ContainModal>
	);

	//Map de resultados de laboratorios o imagenes
	const CardsLaboratories = () => {
		return (
			<MainContain>
				{modal && <DownloadPdfModal />}
				{openPopUp ?
					<PopupError />
					: null
				}
				{data && newData.map((dat) => (

					<ContainMap key={dat.reportId}>

						{isMobile ? '' :
							<ContainImg style={{ paddingInline: 23 }}>
								{dat.type === 'DI' ?
									<Image src={XRayIcon}
										height={4} /> :
									<IconLab className={"fa fa-flask"} style={{ marginRight: '10px' }} ></IconLab>}
							</ContainImg>}

						<ContainInfo style={{ justifyContent: 'center' }}>
							{!isMobile ? '' :
								<Content>
									<ContainImg>
										{dat.type === 'DI' ?
											<Image src={XRayIcon}
												height={4} /> :
											<IconLab className={"fa fa-flask"} style={{ marginRight: '10px' }} ></IconLab>}
									</ContainImg>
									<KeraltyText type={'title'} align={'left'}>{dat.itemName}</KeraltyText>
								</Content>
							}
							{!isMobile ? <Title type={'title'} align={'left'}>{dat.itemName}</Title> : ''}
							{!isMobile ?
								<CardTablet>
									<div>
										<Icon className="fa fa-hospital" style={{ marginRight: '10px', color: '#055293' }} ></Icon>
										<span style={{ fontSize: '16px', fontWeight: '700', color: '#055293', lineHeight: '19px' }}>{t('myHealth.clinicalCenter')}</span>
										<span style={{ marginRight: '10px', fontSize: '14px', fontWeight: '400', color: '#5B5C5B', lineHeight: '17px', marginLeft: 5 }}>{dat.clinicalCenter}</span>
									</div>
									<div>
										<Icon className="fa fa-calendar-day" style={{ marginRight: '10px', color: '#055293' }} ></Icon>
										<span style={{ fontSize: '16px', fontWeight: '700', color: '#055293', lineHeight: '19px' }}>{t('myHealth.orderDate')}</span>
										<span style={{ fontSize: '14px', fontWeight: '400', color: '#5B5C5B', lineHeight: '17px', marginLeft: 5 }}>{moment(dat.orderDate).format("MM/DD/YYYY")}</span>
									</div>
								</CardTablet>
								:
								<div style={{ display: 'flex', flexDirection: 'column' }}>
									<div style={{ marginBlock: 10 }}>
										<Icon className="fa fa-hospital" style={{ marginRight: '10px', color: '#055293' }} ></Icon>
										<span style={{ fontSize: '16px', fontWeight: '700', color: '#055293', lineHeight: '19px' }}>{t('myHealth.clinicalCenter')}</span>
										<span style={{ marginRight: '10px', fontSize: '14px', color: '#5B5C5B', lineHeight: '17px' }}>{dat.clinicalCenter}</span>
									</div>
									<div>
										<Icon className="fa fa-calendar-day" style={{ marginRight: '10px', color: '#055293' }} ></Icon>
										<span style={{ fontSize: '16px', fontWeight: '700', color: '#055293', lineHeight: '19px' }}>{t('myHealth.orderDate')}</span>
										<span style={{ fontSize: '14px', color: '#5B5C5B', lineHeight: '17px' }}>{moment(dat.orderDate).format("MM/DD/YYYY")}</span>
									</div>
								</div>
							}
						</ContainInfo>
						<ContentButton>
							<div style={{ marginLeft: 'auto' }}>
								<PdfButton
									onClick={() => {
										setModal(true)
										setItemToDownload(dat)
									}}
								>
									{t('myHealth.pdfButton')}
									{isMobile ? <img style={{ marginBottom: '-4px' }} src={IconPDF} alt="" /> : ''}
									{isLoading && <Loader />}
								</PdfButton>
							</div>
						</ContentButton>
					</ContainMap>
				))}
				{dataToDisplay.length >= 5 ?
					<Row>
						<Container centered="x" noPadding>
							<Container centered="y" noPadding>
								<PaginateBar
									currentPage={currPage}
									itemsPerPage={5}
									lastPage={lastPage}
									select={(page) => setCurrPage(page)}
									totalItems={dataToDisplay.length}
								/>
							</Container>
						</Container>
					</Row>
					: <></>}
				<Row>
					<div style={{ marginBottom: dataToDisplay.length <= 2 ? 0 : 30, width: '70vw', display: 'flex', background: 'red' }}> </div>
				</Row>
			</MainContain >
		);
	};

	const ClearDate = () => {
		var p1 = { from: "", to: "" };
		var Init = moment().format();
		console.log('dsadsa' + Init)
		setDataObject(p1)
		setVisible(false)
		setIsFiltered(false)
		setFilter(!filter)
	}

	const FilterVisible = () => {
		const format = (date) => { return date?.split('/').reverse().join('/').slice(2).split('/').reverse().join('/') }
		return (
			<>
				<Row noMargin style={{ width: isMobile ? '85%' : '100%', justifyContent: 'space-between', marginLeft: isMobile ? '-7%' : '4%', display: dataObject.from ? 'flex' : 'none' }}>
					<ContentDiv onClick={() => { ClearDate(); setCleanInput(!cleanInput) }}>
						<div style={{ background: '#3CA70D', paddingInline: 10, paddingBlock: 5, borderRadius: 20, paddingTop: 6 }} >
							<KeraltyText style={{ fontSize: '12px', padding: 5, color: '#FFFFFF' }}>{`${format(dataObject.from)} - ${format(dataObject.to)}`}</KeraltyText>
							<KeraltyText style={{ fontSize: '14px', padding: 5, color: '#FFFFFF' }}>   X</KeraltyText>
						</div>
					</ContentDiv>
					<KeraltyText style={{ width: 'auto', marginRight: isMobile ? '-2%' : '3%', alignSelf: 'center', fontSize: '16px', color: '#999999' }} >{t('myHealth.show')} {dataToDisplay.length} {t('myHealth.resul')}</KeraltyText>
				</Row>
			</>
		)
	}


	return (
		<>
			{/* Chat with a Doctor modals */}
			{isLoadingTwo && <Loader />}
			{closeModalError && <ModalErrorMyHealth setCloseModalError={()=>setCloseModalError(false)}/>}
			<ModalInsuranceValidate
				currentChatStatus={currentChatStatus}
				closeModalChatStatus={closeModalChatStatus}
			/>

			<div style={{ position: 'absolute', paddingRight: 15 }}>
				<TabWrapper title={!isMobile ? t('myHealth.labResultsLong') : ''}>
					<Row style={{ width: !isMobile ? '65vw' : '85vw', justifyContent: 'space-between' }}>
						<KeraltyText style={{ width: 'auto', margin: 0, fontSize: 18, marginLeft: filter ? 0/*-10*/ : 0, }} type={'title'} >{isMobile ? t('myHealth.labNimgingResults') : ''}</KeraltyText>
					</Row>

					<Row style={{ width: !isMobile ? '65vw' : '85vw', justifyContent: 'space-between' }}>
						<MainFilter />

						<MainHelp />
					</Row>

					<div style={{
						zIndex: isMobile ? 4 : 1,
						display: filter ? 'none' : 'grid',
						justifyContent: 'space-between',
						width: '90%',
						alignContent: 'center',
					}}>
						<KeraltyFilterDate
							onSuccess={onChangeDate}
							onCancel={() => { setIsModalVisible(false); setFilter(true) }}
							showModalFlag={isModalVisible}
							cleanInput={cleanInput}
						></KeraltyFilterDate>
					</div>

					{visible ? <FilterVisible /> : ''}

					{!error? (data.length == 0 && isFiltered == false) ?
						<div style={isMobile ? styleNoResultMobile : styleNoResult}> <NoSearchResult image={EmptyLabs} subTitle={t('myHealth.noLab')} /></div>
						:
						newData.length == 0 ?
							<NoSearchResult image={NoResult} title={t('myHealth.noResult')} subTitle={t('myHealth.noResultVisitSmmary')} />
							:
							<CardsLaboratories />
					: <></>}
				</TabWrapper>
			</div>
		</>

	);
};


export default LabTable;
