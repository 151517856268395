import { useMemo, useState, useEffect, useCallback } from 'react';
// 3RD PARTY
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'adapter/hooks';
import useIsMobile from 'app/hooks/useIsMobile';
import styled from 'styled-components';
import CryptoJS from 'crypto-js';
// UI
import { HomeBanner, KeraltyModal } from 'app/components/molecules';
import { KeraltyText, Image, KeraltyButton } from 'app/components/atoms';

import { Column, Row, SpaceWrapper, Container } from 'app/components/templates';
import ContentHeader from 'app/screens/home/components/ContentHeader';
import LocationAndServices from 'app/screens/home/components/LocationAndServices';
import FloatingChat from 'app/components/atoms/circularMenu/FloatingChat';
import IconFaceIdDialog from '../../../../app/assets/images/faceid-dialog.png';
import IconTouchDialog from '../../../../app/assets/images/touchid-dialog.png';
// ASSETS
import handCareIcon from '../../../assets/icons/hand-care-duotone.svg';
import thermometerIcon from '../../../assets/icons/thermometer-duotone.svg';
import myHealthIcon from '../../../assets/icons/my-health-duotone.svg';
import calendarIcon from '../../../assets/icons/calendar-duotone.svg';
import bannerImg from '../../../assets/images/banner-image-1.png';
import bannerIcon from '../../../assets/icons/keralty-duotone.svg';
import referralsIcon from '../../../assets/icons/referrals-blue.svg';
import careProgramsIcon from '../../../assets/icons/care-programs-blue.svg';
import labResultsIcon from '../../../assets/icons/lab-results-blue.svg';
import helatLibraryIcon from '../../../assets/icons/healtLibrary-icon.svg';
import ImgModal3 from '../../../assets/icons/hand.svg';
import { ReactComponent as CircleExclamation } from 'app/assets/icons/circle-exclamation.svg';
import locationIcon from 'app/assets/icons/locationicon-filled.svg';
import mhIcon from 'app/assets/icons/mh-webicon.svg';
import wellnessIcon from '../../../assets/icons/wellness.svg';

// ROUTES
import BREAKPOINTS from 'app/ui-core/utils/breakpoints';
// STYLES
import { BannerImg, ButtonWrappers, CordovaColumn, ColumCards } from './styles';
// INTERACTION
import { Link, useLocation } from 'react-router-dom';
import * as sanitasAdapterMethods from 'adapter/sanitasChat/sanitasChat';
import {
  closeSymptomChecker,
  openSymptomChecker,
  showChat,
  chatActions,
} from 'adapter/chat/chatSlice';
import HomeCard from 'app/components/molecules/HomeCard/HomeCard';
import isCordova from 'cordova';
import { UserCredentials as UserCredentialsEntity } from 'domain/entities/userCredentials';
import { KeraltyLongCard } from '../../../components/molecules';

import { userSelectors } from 'adapter/user/userSelectors';
import PrivateHttpClient from '../../../../infrastructure/keraltyApi/privateHttpClient';
import _ from 'lodash';
import ROUTES from '../../../ui-core/utils/routes';
import useAuth from 'app/hooks/useAuth';
import { disableToTN } from 'app/ui-core/utils/disableOptions';
import { KeraltySWS } from 'app/hooks/useKeraltySWS';
import { SESSION_STORAGE_ITEMS } from 'app/ui-core/utils/constants';
import useGeneralErrors from 'app/hooks/useGeneralErrors';
const { MAIN } = ROUTES;

const endpoint = 'auth';
const Header = styled(Row)`
  width: 100%;
  display: flex;
  z-index: 1;
  align-items: center;
  justify-content: space-between;
  top: 81px;
  left: 0px;
  @media (min-width: 1023px) {
    display: none;
  }
  background: rgb(5, 82, 147);
  background: linear-gradient(180deg, rgba(5, 82, 147, 1) 0%, rgba(5, 59, 103, 1) 100%);
`;

const SubTitleTP = styled.span`
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  text-align: center;

  color: #055293;
`;
const TitleModalTersAndPolicy = styled.div`
  margin-top: 25px;
  width: 340px;
  padding-inline: 30px;
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  color: #002d57;
  @media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
    width: 270px;
  }
`;

const Curva = styled.div`
  transform: rotate(180deg);
  display: inline-block;
  width: 100%;
  height: 50px;
  position: relative;
  overflow: hidden;
  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 101%;
    border-bottom-right-radius: 36px;
    box-shadow: 0px 30px 0px 30px #053c68;
    border: 1px solid transparent;
    margin: 0;
    padding: 0;
  }
`;

const BorderRadius = styled(Column)`
  display: flex;
  @media (min-width: 1023px) {
    display: none;
  }
`;
const Content = styled(Row)`
  @media (min-width: ${BREAKPOINTS.minPhoneWidth}) and (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
    z-index: 1;
    position: relative;
    margin-top: 27vh;
  }
`;
const ContainModal = styled(Column)`
  position: fixed;
  display: flex;
  justify-content: center;
  left: 0px;
  right: 0px;
  bottom: 0px;
  height: 100%;
  overflow: auto;
  z-index: 999;
  background-color: #00000058;
  width: 100%;
  @media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
    align-items: center;
  }
`;

const Modal = styled(Column)`
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 20px;
  padding: 10px;
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 15% 5% 0% 5%;
  @media (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
  }
`;

const HomeContent = ({
  getCare,
  language,
  user,
  myHealth,
  mentalHealth,
  referrals,
  labResults,
  carePrograms,
  appointments,
  wellness
}) => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const dispatch = useAppDispatch();
  const isVAOpen = useAppSelector(({ chat }) => chat.currentInstance === 'virtualAssistant');
  const isSCOpen = useAppSelector(({ chat }) => chat.currentInstance === 'symptomChecker');
  const isHLOpen = useAppSelector(({ chat }) => chat.currentInstance === 'healtLibrary');
  const chatState = useAppSelector((state) => state.chat.symptomChecker);
  const isChatOpen = chatState.isOpen;
  const isMobile = useIsMobile();
  const [openPopUp, setOpenPopUp] = useState(true);
  const [modal, setModal] = useState(false);
  const [termsAndPolicyModal, setTermsAndPolicyModal] = useState(false);
  const [anualVisit, setAnualVisit] = useState(false);
  const location = useLocation();

  const [titleModal, settitleModal] = useState('');
  const [newVersionText, setnewVersionText] = useState('');
  const [subTitle1, setsubTitle1] = useState('');
  const [subTitle2, setsubTitle2] = useState('');
  const [dataModal, setdataModal] = useState<any>();
  const { logout } = useAuth();

  const isActiveCoverage = useAppSelector(userSelectors.selectActiveCoverage);

  const userState = user?.state;

  let bytesBackCredential;
  let userBackCredential: UserCredentialsEntity;
  let userEmail = 'no';

  if (KeraltySWS?.swInstance?.getItem(SESSION_STORAGE_ITEMS.CHECKED_BIOMETRIC) === 'true') {
    bytesBackCredential = CryptoJS.AES.decrypt(
      KeraltySWS?.swInstance?.getItem(SESSION_STORAGE_ITEMS.KDC_BACK_CRED) || '{}',
      'MyS4n1t4s'
    );
    userBackCredential = JSON.parse(bytesBackCredential.toString(CryptoJS.enc.Utf8));
    userEmail = userBackCredential.email;
  }

  const triggerHealtLibrary = useCallback(() => {
    dispatch(sanitasAdapterMethods.shutdown());
    if (isSCOpen || isVAOpen) dispatch(closeSymptomChecker());
    if ((!chatState.isChatOpen && !chatState.isInitialized) || isVAOpen || isSCOpen) {
      dispatch(chatActions.openHealtLibrary());
    } else {
      dispatch(showChat());
    }
  }, [dispatch, isSCOpen, chatState, isVAOpen]);

  const shortDescription = (desc) => {
    if (desc && desc.length > 13) {
      return desc.substr(0, 13) + '..';
    }
    return desc;
  };

  const userName = user?.firstName.charAt(0) + user?.firstName.slice(1).toLowerCase();
  const userId = user?.idEcw;

  const rightCars = useMemo(
    () => [
      {
        img: referralsIcon,
        state: userState,
        alt: t('imgNicons.referralsIcon'),
        text: t('routes.referrals'),
        description: t('routes.referralsDescription'),
        onClick: () => {
          push(`/${myHealth}/${referrals}`);
        },
      },
      {
        img: careProgramsIcon,
        state: userState,
        alt: t('imgNicons.careProgramsIcon'),
        text: t('routes.carePrograms'),
        description: t('routes.careProgramsDescription'),
        onClick: () => {
          push(`/${getCare}/${carePrograms}`);
        },
      },
      {
        img: labResultsIcon,
        state: userState,
        alt: t('imgNicons.labAndImagingResultsIcon'),
        text: t('routes.labAndImagingResults'),
        description: t('routes.labAndImagingResultsDescription'),
        onClick: () => {
          push(`/${myHealth}/${labResults}`);
        },
      },
      {
        img: helatLibraryIcon,
        state: userState,
        alt: t('imgNicons.healtLibraryIcon'),
        text: t('routes.healtLibrary'),
        description: t('routes.healtLibraryDescription'),
        onClick: () => triggerHealtLibrary(),
      },
    ],
    [t, push, carePrograms, getCare, labResults, myHealth, referrals, triggerHealtLibrary]
  );

  const cardsTN = useMemo(
    () => {
      const mobileContent = [
        {
          img: handCareIcon,
          state: userState,
          alt: t('imgNicons.getCareIcon'),
          text: t('routes.getCare'),
          description: t('routes.getCareDescription'),
          onClick: () => {
            push(`/${getCare}`);
          },
        },
        // {
        //   img: referralsIcon,
        //   state: userState,
        //   alt: t('imgNicons.referralsIcon'),
        //   text: t('routes.referrals'),
        //   description: t('routes.referralsDescription'),
        //   onClick: () => {
        //     push(`/${myHealth}/${referrals}`);
        //   },
        // },
        // {
        //   img: mhIcon,
        //   state: userState,
        //   alt: t('imgNicons.mhIcon'),
        //   text: t('routes.mh'),
        //   description: t('routes.mhDescription'),
        //   onClick: () => {
        //     push(`/${mentalHealth}`);
        //   },
        // },
        {
          img: calendarIcon,
          state: userState,
          alt: t('imgNicons.medAptIcon'),
          text: t('routes.appointments'),
          description: t('routes.appointmentsDescription'),
          onClick: () => {
            push(`/${getCare}/${appointments}`);
          },
        },
        {
          img: myHealthIcon,
          state: userState,
          atl: t('imgNicons.healthReportIcon'),
          text: t('routes.myHealth'),
          description: t('routes.myHealthDescription'),
          onClick: () => {
            push(`/${myHealth}`);
          },
        },
        {
          img: wellnessIcon,
          state: userState,
          alt: t('imgNicons.wellnessIcon'),
          text: t('routes.wellness'),
          description: t('routes.wellnessDescription'),
          onClick: () => {
            push(`/${wellness}`);
          },
        },
        // {
        //   img: labResultsIcon,
        //   state: userState,
        //   alt: t('imgNicons.labAndImagingResultsIcon'),
        //   text: t('routes.labAndImagingResults'),
        //   description: t('routes.labAndImagingResultsDescription'),
        //   onClick: () => {
        //     push(`/${myHealth}/${labResults}`);
        //   },
        // },
        // {
        //   img: locationIcon,
        //   state: userState,
        //   alt: t('imgNicons.locationIcon'),
        //   text: t('routes.location'),
        //   description: t('routes.locationDescription'),
        //   onClick: () => {
        //     window.open('https://www.mysanitas.com/en/fl#state-locations', '_blank');
        //   },
        // },
      ];

      const webContent = [
        {
          img: handCareIcon,
          state: userState,
          alt: t('imgNicons.getCareIcon'),
          text: t('routes.getCare'),
          description: t('routes.getCareDescription'),
          onClick: () => {
            push(`/${getCare}`);
          },
        },
        {
          img: referralsIcon,
          state: userState,
          alt: t('imgNicons.referralsIcon'),
          text: t('routes.referrals'),
          description: t('routes.referralsDescription'),
          onClick: () => {
            push(`/${myHealth}/${referrals}`);
          },
        },
        // {
        //   img: mhIcon,
        //   state: userState,
        //   alt: t('imgNicons.mhIcon'),
        //   text: t('routes.mh'),
        //   description: t('routes.mhDescription'),
        //   onClick: () => {
        //     push(`/${mentalHealth}`);
        //   },
        // },
        {
          img: calendarIcon,
          state: userState,
          alt: t('imgNicons.medAptIcon'),
          text: t('routes.appointments'),
          description: t('routes.appointmentsDescription'),
          onClick: () => {
            push(`/${getCare}/${appointments}`);
          },
        },
        {
          img: wellnessIcon,
          state: userState,
          alt: t('imgNicons.wellnessIcon'),
          text: t('routes.wellness'),
          description: t('routes.wellnessDescription'),
          onClick: () => {
            push(`/${wellness}`);
          },
        },
        {
          img: myHealthIcon,
          state: userState,
          atl: t('imgNicons.healthReportIcon'),
          text: t('routes.myHealth'),
          description: t('routes.myHealthDescription'),
          onClick: () => {
            push(`/${myHealth}`);
          },
        },
        {
          img: labResultsIcon,
          state: userState,
          alt: t('imgNicons.labAndImagingResultsIcon'),
          text: t('routes.labAndImagingResults'),
          description: t('routes.labAndImagingResultsDescription'),
          onClick: () => {
            push(`/${myHealth}/${labResults}`);
          },
        },
        {
          img: locationIcon,
          state: userState,
          alt: t('imgNicons.locationIcon'),
          text: t('routes.location'),
          description: t('routes.locationDescription'),
          onClick: () => {
            window.open('https://www.mysanitas.com/en/fl#state-locations', '_blank');
          },
        },
      ];

      return isMobile ? mobileContent : webContent;
    },
    [
      t,
      dispatch,
      user,
      push,
      chatState.isInitialized,
      isChatOpen,
      language,
      isVAOpen,
      myHealth,
      getCare,
      appointments,
      isHLOpen,
    ]
  );

  const cards = useMemo(() => {
    const mobileContent = [
      {
        img: handCareIcon,
        state: userState,
        alt: t('imgNicons.getCareIcon'),
        text: t('routes.getCare'),
        description: t('routes.getCareDescription'),
        onClick: () => {
          push(`/${getCare}`);
        },
      },
      {
        img: mhIcon,
        state: userState,
        alt: t('imgNicons.mhIcon'),
        text: t('routes.mh'),
        description: t('routes.mhDescription'),
        onClick: () => {
          push(`/mental-health`);
        },
      },
      {
        img: myHealthIcon,
        state: userState,
        atl: t('imgNicons.healthReportIcon'),
        text: t('routes.myHealth'),
        description: t('routes.myHealthDescription'),
        onClick: () => {
          push(`/${myHealth}`);
        },
      },
      {
        img: calendarIcon,
        state: userState,
        alt: t('imgNicons.medAptIcon'),
        text: t('routes.appointments'),
        description: t('routes.appointmentsDescription'),
        onClick: () => {
          push(`/${getCare}/${appointments}`);
        },
      },
      // {
      //   img: helatLibraryIcon,
      //   state: userState,
      //   alt: t('imgNicons.healtLibraryIcon'),
      //   text: t('routes.healtLibrary'),
      //   description: t('routes.healtLibraryDescription'),
      //   onClick: () => triggerHealtLibrary(),
      // },
      // {
      //   img: thermometerIcon,
      //   state: userState,
      //   alt: t('imgNicons.thermometerIcon'),
      //   text: t('routes.check'),
      //   description: t('routes.checkDescription'),
      //   onClick: () => {
      //     dispatch(sanitasAdapterMethods.shutdown());
      //     if (isVAOpen || isHLOpen) dispatch(closeSymptomChecker());
      //     // setTimeout(async () => {
      //     if ((!isChatOpen && !chatState.isInitialized) || isVAOpen || isHLOpen) {
      //       dispatch(
      //         openSymptomChecker({
      //           isOpen: true,
      //           isPublic: false,
      //           lng: language,
      //           userInfo: {
      //             dob: user.birthdate,
      //             gender: user.sex,
      //             memberid: user.idEcw,
      //             organization_user_id: user.idEcw,
      //           },
      //         })
      //       );
      //     } else {
      //       dispatch(showChat());
      //     }
      //   },
      // },
    ];

    const webContent = [
      {
        img: handCareIcon,
        state: userState,
        alt: t('imgNicons.getCareIcon'),
        text: t('routes.getCare'),
        description: t('routes.getCareDescription'),
        onClick: () => {
          push(`/${getCare}`);
        },
      },
      {
        img: referralsIcon,
        state: userState,
        alt: t('imgNicons.referralsIcon'),
        text: t('routes.referrals'),
        description: t('routes.referralsDescription'),
        onClick: () => {
          push(`/${myHealth}/${referrals}`);
        },
      },
      {
        img: calendarIcon,
        state: userState,
        alt: t('imgNicons.medAptIcon'),
        text: t('routes.appointments'),
        description: t('routes.appointmentsDescription'),
        onClick: () => {
          push(`/${getCare}/${appointments}`);
        },
      },
      {
        img: mhIcon,
        state: userState,
        alt: t('imgNicons.mhIcon'),
        text: t('routes.mh'),
        description: t('routes.mhDescription'),
        onClick: () => {
          push(`/${mentalHealth}`);
        },
      },
      {
        img: wellnessIcon,
        state: userState,
        alt: t('imgNicons.wellnessIcon'),
        text: t('routes.wellness'),
        description: t('routes.wellnessDescription'),
        onClick: () => {
          push(`/${wellness}`);
        },
      },
      {
        img: myHealthIcon,
        state: userState,
        atl: t('imgNicons.healthReportIcon'),
        text: t('routes.myHealth'),
        description: t('routes.myHealthDescription'),
        onClick: () => {
          push(`/${myHealth}`);
        },
      },
      {
        img: labResultsIcon,
        state: userState,
        alt: t('imgNicons.labAndImagingResultsIcon'),
        text: t('routes.labAndImagingResults'),
        description: t('routes.labAndImagingResultsDescription'),
        onClick: () => {
          push(`/${myHealth}/${labResults}`);
        },
      },
      // {
      //   img: helatLibraryIcon,
      //   state: userState,
      //   alt: t('imgNicons.healtLibraryIcon'),
      //   text: t('routes.healtLibrary'),
      //   description: t('routes.healtLibraryDescription'),
      //   onClick: () => triggerHealtLibrary(),
      // },
      // {
      //   img: thermometerIcon,
      //   state: userState,
      //   alt: t('imgNicons.thermometerIcon'),
      //   text: t('routes.check'),
      //   description: t('routes.checkDescription'),
      //   onClick: () => {
      //     dispatch(sanitasAdapterMethods.shutdown());
      //     if (isVAOpen || isHLOpen) dispatch(closeSymptomChecker());
      //     // setTimeout(async () => {
      //     if ((!isChatOpen && !chatState.isInitialized) || isVAOpen || isHLOpen) {
      //       dispatch(
      //         openSymptomChecker({
      //           isOpen: true,
      //           isPublic: false,
      //           lng: language,
      //           userInfo: {
      //             dob: user.birthdate,
      //             gender: user.sex,
      //             memberid: user.idEcw,
      //             organization_user_id: user.idEcw,
      //           },
      //         })
      //       );
      //     } else {
      //       dispatch(showChat());
      //     }
      //   },
      // },
      {
        img: locationIcon,
        state: userState,
        alt: t('imgNicons.locationIcon'),
        text: t('routes.location'),
        description: t('routes.locationDescription'),
        onClick: () => {
          window.open('https://www.mysanitas.com/en/fl#state-locations', '_blank');
        },
      },
    ];
    return isMobile ? mobileContent : webContent;
  }, [
    t,
    dispatch,
    user,
    push,
    chatState.isInitialized,
    isChatOpen,
    language,
    isVAOpen,
    myHealth,
    mentalHealth,
    getCare,
    appointments,
    isHLOpen,
  ]);

  const styleButton = {
    color: '#3CA70D',
    marginRight: 60,
    marginLeft: 60,
    marginTop: 10,
    paddingTop: 0,
    paddingBottom: 0,
    backgroundColor: '#FFFFFF',
    borderRadius: 40,
    border: 'none',
    borderColor: '#FFFFFF',
    fontSize: 16,
    fontWeight: 'bold',
  };

  const styleDiv = {
    marginTop: 0,
    paddingTop: 0,
    paddingBottom: 10,
    marginLeft: 0,
    marginRight: 0,
    flex: 1,
    align: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
  };

  const styleText = {
    textAlign: 'center',
    marginRight: 7,
    color: '#5b5c5b',
    fontWeight: 400,
  };

  const styleTextTittle = {
    textAlign: 'center',
    marginRight: 7,
    color: '#3ca70d',
    widht: '100%',
  };
  const styleIcon = {
    align: 'center',
    margin: 20,
    paddingBottom: 5,
    fontSize: 32,
    fontWeight: 'bold',
    marginLeft: '41%',
  };

  const LongCard = styled(KeraltyLongCard)`
    margin-top: 1rem;
    padding: 5%;
    display: flex;
    align-items: center !important;
    box-shadow: 0px 4px 4px rgba(1, 42, 88, 0.25);
  `;

  const MaintContainer = styled(Column)`
    margin-bottom: 5px;
  `;

  const handleVeryfyPlatform = () => {
    let platform;
    // if IOS
    if (!!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)) {
      platform = 'ios';
      // If Android
    } else if (navigator.userAgent.toLowerCase().indexOf('android') > -1) {
      platform = 'android';
      // If other OS
    } else {
      platform = 'other';
    }

    return platform;
  };

  const PopupFace = () => {
    return (
      <div>
        <KeraltyModal size="long">
          <Row noMargin>
            <div style={styleIcon}>
              {handleVeryfyPlatform() === 'ios' ? (
                <img src={IconFaceIdDialog} alt="FaceId Icon" height="40px" width="40px" />
              ) : (
                <img src={IconTouchDialog} alt="TouchId Icon" height="40px" width="40px" />
              )}
            </div>

            <SpaceWrapper mb={1}>
              <Container
                noPadding
                noMargin
                style={{ display: 'flex', width: '280px', justifyContent: 'center' }}
              >
                {handleVeryfyPlatform() === 'ios' ? (
                  <KeraltyText type={'label'} align={'center'} style={styleTextTittle}>
                    {t('firstBiologinTittleIos')}
                  </KeraltyText>
                ) : (
                  <KeraltyText type={'label'} align={'center'} style={styleTextTittle}>
                    {t('firstBiologinTittle')}
                  </KeraltyText>
                )}
              </Container>
            </SpaceWrapper>

            <SpaceWrapper mb={1}>
              <Container centered={'x'} noPadding noMargin>
                {handleVeryfyPlatform() === 'ios' ? (
                  <KeraltyText type={'label'} align={'center'} style={styleText}>
                    {t('firstBiologinIos')}
                  </KeraltyText>
                ) : (
                  <KeraltyText type={'label'} align={'center'} style={styleText}>
                    {t('firstBiologin')}
                  </KeraltyText>
                )}
              </Container>
            </SpaceWrapper>
          </Row>
          <Row noMargin>
            <div style={styleDiv}>
              <button
                style={styleButton}
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  setOpenPopUp(false);
                  KeraltySWS?.swInstance?.setItem(SESSION_STORAGE_ITEMS.HAVE_LOGED, userEmail);
                }}
              >
                {t('ok')}
              </button>
            </div>
          </Row>
        </KeraltyModal>
      </div>
    );
  };

  // const response = await PrivateHttpClient.get(`${endpoint}/checkAnnualVisit/` + user.idEcw + '/FL');
  const openModalWarning = (
    titleModal: string,
    newVersionText: string,
    subTitle1: string,
    subTitle2: string,
    dataModal: any
  ) => {
    settitleModal(titleModal);
    setnewVersionText(newVersionText);
    setsubTitle1(subTitle1);
    setsubTitle2(subTitle2);
    setdataModal(dataModal);

    setTermsAndPolicyModal(true);
  };

  const updateTermsAndPrivacy = useCallback(async () => {
    try {
      const headers = {
        'Content-Type': 'application/json',
        recaptcha:
          '03AGdBq26Jdo7Qob1O9m4xcCHdLoV9uS75dIW4neNgfDkC1turMf_9I2OGZXinG8Ys7sbkfqYU_MXFT1QVoIxOCIpValAOqcvYjGKRmQ-NDWE-imfE3x9pTOi2WnsBqjg75vJDdAQy5Jo9wr-J811F6lNZOfoLSBvf-NM4rjuSrDOILkYY0vxDH3XOJysmXes1jO1zDXbocLMUNRdTx295t2RCAFMLuj_i7zCcmi-S5iWhMc64M32sZcHzgY8xxJci0qRjoFgW7uGzUCQ6VURO8_Ko4OJQE7ea1xSPNa97p68i3U0QcxV1tcOJsiXczeMo3kzu9TaRozdDz3TU99SmSslUnpY34WsK37CNiBz9HnaHJuBOocbEpojjCMH2xk51J170nxmkGnYNpqg-pEAPlhB9PT5TZJL0764bou9Ii5LTkf42O7KSBvUu4pAXzpEa9YcKyaIRoTk52UhJomlqVqJLX9ZZJAhgM7PbwScc040VuqI9FYcDsvLlFP__70Te80DAJ1B948cnfuYAU9LtufkBg7cHmJm96k6mOh12KF5F-FzeWAXaV2EAzHPU3A8fZ843FkVVdHtZ9B0fDp1t6EZBAKoa-tNPAC5WKParOZmrvabu7rQX3j8vCRJayL_ZufbaNLYTRxnSOzstDNP1xTFegEd3McafmOW-P2YoxuU3G58gbozeEnNPSzBOiO1i7vsOL25BKmIbyqQOeTDwveZy9J8uUeXti8GZMPSyCUD5uqw-0yaxuDG3qZUl9Irayp6lt79ocKyFnRcsvrPvToV2mhbLGEl256XLodE3emFEZ2f9gT0tS8s6v1rxQTzHAwgx6YTCdhTsjy1sE5M9M3-qpprEqJEZK0iYqw9ew2HB1lckyJbRNBZoPIKYFUb8pAgiKCwdpudehE7lK4zC--_G6CJUoxNrI8MEir5E4zHPFEol3oNXKIH5zH7CnWJ1TCqvu93ejhZDl5CjwZHSK56Ed_MazzTv17OOj0rnQo8vZBXpj4x7RhXSNz3REwpKhaJFCeIaJ-Fz56Zb1njNJ2LHWjkxzWU5ekOufBeWYIp46cczltvjt-nNYhH6GtNH99VURJdW1vkB4ggTtFk5_DGR_SnwF-saBI-rrQFw623tKlo5TWci869XxjBMeYGnjBS2PIubVYd1UmRTFdNnEuz805cBcM6PPpIH3ziWrdZTt6OfGnpLgX8p8yMxNoatyGAnOaS64yv',
      };
      const body = {
        versionPrivacy: true,
        versionTerms: true,
        state: user?.state,
        authId: user?.authUid,
      };
      await PrivateHttpClient.post('auth/updateUsers/updateTermsAndPrivacyVersion', body, {
        headers: headers,
      });
      getShow();
    } catch (error) {
      getShow();
    }
  }, []);

  const handlerCheckTermsAndPrivacyVersion = useCallback(async () => {
    try {
      const headers = {
        'Content-Type': 'application/json',
        recaptcha:
          '03AGdBq26Jdo7Qob1O9m4xcCHdLoV9uS75dIW4neNgfDkC1turMf_9I2OGZXinG8Ys7sbkfqYU_MXFT1QVoIxOCIpValAOqcvYjGKRmQ-NDWE-imfE3x9pTOi2WnsBqjg75vJDdAQy5Jo9wr-J811F6lNZOfoLSBvf-NM4rjuSrDOILkYY0vxDH3XOJysmXes1jO1zDXbocLMUNRdTx295t2RCAFMLuj_i7zCcmi-S5iWhMc64M32sZcHzgY8xxJci0qRjoFgW7uGzUCQ6VURO8_Ko4OJQE7ea1xSPNa97p68i3U0QcxV1tcOJsiXczeMo3kzu9TaRozdDz3TU99SmSslUnpY34WsK37CNiBz9HnaHJuBOocbEpojjCMH2xk51J170nxmkGnYNpqg-pEAPlhB9PT5TZJL0764bou9Ii5LTkf42O7KSBvUu4pAXzpEa9YcKyaIRoTk52UhJomlqVqJLX9ZZJAhgM7PbwScc040VuqI9FYcDsvLlFP__70Te80DAJ1B948cnfuYAU9LtufkBg7cHmJm96k6mOh12KF5F-FzeWAXaV2EAzHPU3A8fZ843FkVVdHtZ9B0fDp1t6EZBAKoa-tNPAC5WKParOZmrvabu7rQX3j8vCRJayL_ZufbaNLYTRxnSOzstDNP1xTFegEd3McafmOW-P2YoxuU3G58gbozeEnNPSzBOiO1i7vsOL25BKmIbyqQOeTDwveZy9J8uUeXti8GZMPSyCUD5uqw-0yaxuDG3qZUl9Irayp6lt79ocKyFnRcsvrPvToV2mhbLGEl256XLodE3emFEZ2f9gT0tS8s6v1rxQTzHAwgx6YTCdhTsjy1sE5M9M3-qpprEqJEZK0iYqw9ew2HB1lckyJbRNBZoPIKYFUb8pAgiKCwdpudehE7lK4zC--_G6CJUoxNrI8MEir5E4zHPFEol3oNXKIH5zH7CnWJ1TCqvu93ejhZDl5CjwZHSK56Ed_MazzTv17OOj0rnQo8vZBXpj4x7RhXSNz3REwpKhaJFCeIaJ-Fz56Zb1njNJ2LHWjkxzWU5ekOufBeWYIp46cczltvjt-nNYhH6GtNH99VURJdW1vkB4ggTtFk5_DGR_SnwF-saBI-rrQFw623tKlo5TWci869XxjBMeYGnjBS2PIubVYd1UmRTFdNnEuz805cBcM6PPpIH3ziWrdZTt6OfGnpLgX8p8yMxNoatyGAnOaS64yv',
      };
      const res: any = await PrivateHttpClient.post(
        'auth/checkTermsAndPrivacyVersion',
        { email: user?.email, state: user?.state },
        { headers: headers }
      );

      if (res?.policy === true && res?.terms === true) getShow();
      if (res?.policy === false && res?.terms === false) {
        openModalWarning(
          t('home.termAndPrivacy.tAndP'),
          t('home.termAndPrivacy.changeTwo'),
          t('home.termAndPrivacy.changeTwo1'),
          t('home.termAndPrivacy.changeTwo2'),
          res
        );
      } else if (res?.terms === false) {
        openModalWarning(
          t('home.termAndPrivacy.term'),
          t('home.termAndPrivacy.changeOne'),
          t('home.termAndPrivacy.changeOne1'),
          t('home.termAndPrivacy.changeOne2'),
          res
        );
      } else if (res?.policy === false) {
        openModalWarning(
          t('home.termAndPrivacy.privacy'),
          t('home.termAndPrivacy.changeOne'),
          t('home.termAndPrivacy.changeOne1'),
          t('home.termAndPrivacy.changeOne2'),
          res
        );
      } else {
        console.log('policy, 3 terms', res?.policy, res?.terms);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    handlerCheckTermsAndPrivacyVersion();
    // getShow()
    KeraltySWS?.swInstance?.setItem(SESSION_STORAGE_ITEMS.LOGGED, 'true');
  }, []);
  useEffect(() => {
    // sessionStorage.setItem("haveLoged", 'true');
    //console.log('storage home', sessionStorage)
    //console.log('compare', userBackCredential!== userBackCredentialSaved )
    //console.log('userBackCredentialSaved',userBackCredentialSaved )
    //console.log('userBackCredentialSaved',bytesBackCredentialSaved )
    //console.log('userBackCredential', userEmail)
  });
  let top;

  if (handleVeryfyPlatform() !== 'ios') {
    top = '3rem ';
  } else {
    top = '5rem';
  }

  const updateAnnualVisit = async () => {
    const response = await PrivateHttpClient.get(
      `${endpoint}/updateUsers/updateAnnualVisit/${user.idEcw}/${userState}/1`
    );
    KeraltySWS?.swInstance?.setItem(SESSION_STORAGE_ITEMS.NEW_VISIT, 'false');
    setAnualVisit(false);
    setModal(false);
  };
  const getShow = async () => {
    let show = KeraltySWS?.swInstance?.getItem(SESSION_STORAGE_ITEMS.NEW_VISIT);

    if (show === null || show === 'true') {
      const response = await PrivateHttpClient.get(
        `${endpoint}/checkAnnualVisit/${user.idEcw}/${userState}`
      );
      setModal(!response);
    } else {
      setModal(false);
    }
    KeraltySWS?.swInstance?.setItem(SESSION_STORAGE_ITEMS.NEW_VISIT, 'false');
  };
  const handleTelevisit = async () => {
    !disableToTN(user?.state) && KeraltySWS?.swInstance?.setItem(SESSION_STORAGE_ITEMS.HANDLE_TELEVISIT, 'true');
    push(`/${MAIN.getCare}`);
    // setModal(false)
    // if (isActiveCoverage || window.KERALTY_CONFIG) {
    //   isCordova ? window.cordova.InAppBrowser.open(linkToTelevisit, '_system') : window.open(linkToTelevisit, '_blank')
    // } else {
    //   push(`/${MAIN.getCare}/${GET_CARE.getCareNow}/${GET_CARE.televisit}`)
    // }
  };
  const handleOnClose = () => {
    KeraltySWS?.swInstance?.setItem(SESSION_STORAGE_ITEMS.NEW_VISIT, 'false');
    setModal(false);
  };

  const getOut = () => {
    KeraltySWS?.swInstance?.setItem(SESSION_STORAGE_ITEMS.HAVE_LOGED, 'true');
    logout();
  };

  const ModalAnnual = () => {
    return (
      <ContainModal noPadding>
        {modal && (
          <Modal>
            <div style={{ padding: '10px' }}>
              <img style={{ background: '#3CA70D', borderRadius: '50%' }} src={ImgModal3} alt="" />
            </div>
            <div style={{ alignSelf: 'center', textAlign: 'center', padding: '5px' }}>
              <KeraltyText
                color="#0069A7"
                type="info"
                align="center"
                fontSize="20px"
                bold="false"
                fontWeight="400"
                fontFamily="Nova"
                style={{ fontWeight: 400 }}
              >
                {t('home.modalDesc')}
              </KeraltyText>
            </div>
            <div>
              <KeraltyButton
                type={'submit'}
                full
                onClick={() => handleTelevisit()}
                style={{ fontWeight: 400 }}
              >
                {t('home.modalButton')}
              </KeraltyButton>
            </div>
            <div style={{ width: '75%', textAlign: 'center' }}>
              <KeraltyText
                color="#0069A7"
                type="info"
                align="center"
                fontSize="16px"
                bold="false"
                underline="true"
                fontFamily="Nova"
                onClick={() => handleOnClose()}
                style={{ fontWeight: 400 }}
              >
                {t('home.modalRemember')}
              </KeraltyText>
            </div>
            <div style={{ width: '75%', textAlign: 'center', padding: '5px' }}>
              <KeraltyText
                color="#0069A7"
                type="info"
                align="center"
                bold="false"
                underline="true"
                onClick={() => updateAnnualVisit()}
                style={{ fontWeight: 400 }}
              >
                {t('home.modalAnnual')}
              </KeraltyText>
            </div>
          </Modal>
        )}
      </ContainModal>
    );
  };

  const ModalTermsAndPolicy = () => {
    return (
      <ContainModal noPadding>
        {termsAndPolicyModal && (
          <Modal>
            <Container />
            <CircleExclamation></CircleExclamation>
            <TitleModalTersAndPolicy>{titleModal}</TitleModalTersAndPolicy>
            <Container />

            <div style={{ width: 300, textAlign: 'center' }}>
              <SubTitleTP>{subTitle1}</SubTitleTP>
              <Link
                to={{
                  pathname:
                    dataModal?.policy === true && dataModal?.terms === false
                      ? '/legal/terms-of-service'
                      : '/legal/privacy-policy',
                  state: { from: location.pathname },
                }}
                style={{ textDecoration: 'none', borderBottom: '.5px solid #055293' }}
              >
                <SubTitleTP
                  onClick={() => setTermsAndPolicyModal(false)}
                  style={{ fontWeight: 600, cursor: 'pointer' }}
                >
                  {newVersionText}
                </SubTitleTP>
              </Link>
              <SubTitleTP>{subTitle2}</SubTitleTP>
            </div>

            <div>
              <KeraltyButton
                type={'submit'}
                full
                onClick={() => {
                  updateTermsAndPrivacy();
                  setTermsAndPolicyModal(false);
                }}
              >
                {t('button.accept2')}
              </KeraltyButton>
            </div>
            <div
              onClick={() => {
                setTermsAndPolicyModal(false);
                getOut();
              }}
              style={{
                cursor: 'pointer',
                marginBottom: 20,
                color: '#055293',
                fontSize: '20px',
                fontWeight: '600',
                border: 'none',
                padding: 0,
                background: 'none',
                fontFamily: 'Proxima Nova',
                textDecoration: 'underline',
              }}
            >
              {t('button.cancel')}
            </div>
          </Modal>
        )}
      </ContainModal>
    );
  };

  return (
    <>
      {modal ? <ModalAnnual /> : ''}
      {termsAndPolicyModal ? <ModalTermsAndPolicy /> : ''}
      {isCordova &&
      openPopUp &&
      userEmail !== KeraltySWS?.swInstance?.getItem(SESSION_STORAGE_ITEMS.HAVE_LOGED) &&
      KeraltySWS?.swInstance?.getItem(SESSION_STORAGE_ITEMS.CHECKED_BIOMETRIC) === 'true' ? (
        <PopupFace />
      ) : null}
      <div
        style={{
          display: 'flex',
          position: 'fixed',
          flexDirection: 'column',
          zIndex: 4,
        }}
      >
        <Header style={{ paddingTop: top, height: 140, marginBottom: 0 }}>
          <Column sm={12}>
            <KeraltyText
              color={'light'}
              bold
              style={{ fontSize: '24px', paddingLeft: '3%', whiteSpace: 'pre' }}
            >
              {`${t('home.hi')}${shortDescription(userName) ?? ''}!`}
            </KeraltyText>
          </Column>
          <Column sm={12}>
            <KeraltyText
              type="label"
              color={'light'}
              bold={false}
              style={{
                fontSize: '16px',
                lineHeight: 'normal',
                color: '#FFFFFF',
                paddingTop: 10,
                paddingLeft: '3%',
                paddingRight: '3%',
                fontWeight: '300',
                textAlign: 'left',
              }}
            >
              {t('home.welcome')}
            </KeraltyText>
          </Column>
          <Column sm={12} style={{ marginBottom: 5 }}>
            {user?.idEcw ? (
              <div
                style={{
                  marginLeft: '3%',
                  display: 'flex',
                  flexDirection: 'column',
                  width: '75%',
                  textAlign: 'left',
                  paddingBottom: '15px',
                }}
              >
                <KeraltyText
                  align={'left'}
                  type="info"
                  bold={true}
                  style={{
                    color: '#FFFFFF',
                    paddingTop: 10,
                    fontSize: '14px',
                  }}
                >
                  {t('home.yourId')}
                  <KeraltyText
                    type="info"
                    align={'left'}
                    style={{
                      color: '#FFFFFF',
                      fontSize: '14px',
                      paddingTop: '1px',
                    }}
                  >
                    {userId ? userId : ''}
                  </KeraltyText>
                </KeraltyText>
              </div>
            ) : (
              <div
                style={{
                  marginLeft: '3%',
                  display: 'flex',
                  flexDirection: 'column',
                  width: '75%',
                  textAlign: 'left',
                  paddingBottom: '15px',
                  height: '37px',
                }}
              ></div>
            )}
          </Column>
        </Header>
        <Column noPadding>
          <Curva />
        </Column>
      </div>

      <Content>
        {/* <CordovaColumn lg={3} md={0} sm={0}>
          <Row>
            {rightCars.map((card, i) => (
              <Column key={`card-${i}`} lg={12} md={12} sm={12}>
                <div style={{ margin: '-.5rem 0', width: '100%' }}>
                  {disableToTN(user?.state) && card.text == t('routes.carePrograms') ? null : (
                    <HomeCard {...card} margin={0} />
                  )}
                </div>
              </Column>
            ))}
          </Row>
        </CordovaColumn> */}
        <Column lg={12}>
          <Row stretch>
            <ContentHeader />
            {disableToTN(user?.state) ? (
              <>
                <Row>
                  {isMobile ? (
                    <ButtonWrappers>
                      {cardsTN.map((card, i) => (
                        <Column key={`card-${i}`} lg={8} md={12} sm={12}>
                          {userState == 'TN' && card.text == t('routes.location') ? null : (
                            <HomeCard {...card} />
                          )}
                        </Column>
                      ))}
                    </ButtonWrappers>
                  ) : (
                    <ButtonWrappers>
                      {cardsTN.map((card, i) => (
                        <Column key={`card-${i}`} lg={12} md={12} sm={12}>
                          <HomeCard {...card} />
                        </Column>
                      ))}
                    </ButtonWrappers>
                  )}
                </Row>
                {/* {isMobile && (
                  <MaintContainer noPadding>
                    <LongCard
                      onClick={() => triggerHealtLibrary()}
                      style={{ padding: '5px 2rem', margin: '0 20px' }}
                    >
                      <Image
                        src={helatLibraryIcon}
                        size={4}
                        style={{ marginRight: '25px', marginInlineStart: '5px' }}
                        alt={t('imgNicons.healtLibraryIcon')}
                      />
                      <Row>
                        <KeraltyText type="label" bold={false}>
                          {t('routes.healtLibrary')}
                        </KeraltyText>
                        <KeraltyText
                          type="info"
                          color="#5B5C5B"
                          style={{
                            textAlign: 'left',
                            lineHeight: 1.3,
                            marginTop: 5,
                            color: '#5B5C5B',
                            fontSize: 12,
                          }}
                          fontSize={12}
                          bold
                          adaptable={false}
                        >
                          {t('routes.healtLibraryDescription')}
                        </KeraltyText>
                      </Row>
                    </LongCard>
                  </MaintContainer>
                )} */}
              </>
            ) : (
              <>
                <Row style={{marginBottom: 0}}>
                  <ButtonWrappers>
                    {cards.map((card, i) => (
                      <Column key={`card-${i}`} lg={12} md={12} sm={12}>
                        {userState == 'TN' && card.text == t('routes.myHealth') ? null : (
                          <HomeCard {...card} />
                        )}
                      </Column>
                    ))}
                  </ButtonWrappers>
                </Row>
                <Row style={{marginTop: 0}}>
                  {isMobile && (
                    <MaintContainer noPadding>
                      <LongCard
                        onClick={() => push(`/${wellness}`)}
                        style={{ padding: '5px 2rem', margin: '0 20px' }}
                      >
                        <Image
                          src={wellnessIcon}
                          size={4}
                          style={{ marginRight: '25px', marginInlineStart: '5px' }}
                          alt={t('imgNicons.wellnessIcon')}
                        />
                        <Row>
                          <KeraltyText type="label" bold={false} style={{fontWeight: isMobile ? '600' : '400'}}>
                            {t('routes.wellness')}
                          </KeraltyText>
                          <KeraltyText
                            type="info"
                            color="#5B5C5B"
                            style={{
                              textAlign: 'left',
                              lineHeight: 1.3,
                              marginTop: 5,
                              color: '#5B5C5B',
                              fontSize: 12,
                            }}
                            fontSize={12}
                            bold
                            adaptable={false}
                          >
                            {t('routes.wellnessDescription')}
                          </KeraltyText>
                        </Row>
                      </LongCard>
                    </MaintContainer>
                  )}
                </Row>
              </>
            )}
            <CordovaColumn lg={12}>
              <HomeBanner
                img={bannerImg}
                icon={bannerIcon}
                title={t('home.banner')}
                desc={t('home.bannerDesc')}
              />
            </CordovaColumn>
          </Row>
        </Column>
        {disableToTN(user?.state) ? (
          <Column>
            <LocationAndServices />
          </Column>
        ) : (
          <></>
        )}
        {/* {isMobile && <FloatingChat />} */}
      </Content>
    </>
  );
};

export default HomeContent;
