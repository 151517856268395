const nsFormLabel = {
  en: {
    accountNumber: 'Sanitas Account Number',
    address: 'Address (apt, etc.)',
    birthdate: 'Date of birth',
    city: 'City',
    companyId: 'Company ID',
    date: 'mm/ dd/ yyyy',
    dateLabel: 'Registration date',
    documents: 'Documents (emancipation, ID)',
    documentTypes: '*Allowed types: JPG, PDF, PNG, DOC, HEIC',
    email: 'Email',
    mail: 'E-mail',
    firstName: 'First name',
    groupId: 'Group number ID',
    groupId2: 'Group ID',
    guarantor: 'Legal Guardian (Guarantor)',
    guarantorBirthdate: 'Guarantor date of birth',
    guarantorReason: 'Reason to have a guarantor',
    guarantorSsn: 'Guarantor SSN',
    garantorSsnLabel: 'Guarantor’s SSN',
    selfSsn: 'Self SSN',
    insuranceCompany: 'Insurance company',
    insuranceInfo2nd: 'Información del seguro secundario',
    insuredLastName: 'Last name of primary insured',
    insuredLastName2: 'Last name of primary insured',
    insuredName: 'Name of primary insured',
    insuredName2: 'Name of primary insured',
    lastName: 'Last name',
    homePhone: 'Home phone',
    movilPhone: 'Mobile phone',
    movilNumber: 'Mobile number',
    textAddress: 'Address',
    sex: 'Gender at birth',
    sexF: 'Female',
    sexM: 'Male',
    sexD: 'Unknown',
    dateOfBirth: 'Date of birth',
    btnUpdateInformation: 'Update information',
    btnReturnInformation: 'Return',
    titleAlertConfirm: 'Are you sure you want to save this changes ?',
    titleSuccessConfirm: "Your contact information has been updated. The information updated will be reflected tomorrow. Thank you.",
    btnTitleSuccessConfirm: "Accept",
    maritalStatus: 'Marital status',
    maxMbSize: 'Max size: 3MB',
    optional: 'optional',
    password: 'Password',
    patientRelationship: 'Relationship with patient',
    patientRelationship2nd: 'Patient relationship to insured',
    patientRelationship2nd2: 'Patient relationship to insured',
    phoneNumber: 'Phone number',
    relationshipToInsured: 'Patient relationship to insured',
    relationshipToInsured2nd: 'Patient relationship to insured',
    responsibleFirstName: 'Responsible party first name',
    responsibleLastName: 'Responsible party last name',
    responsibleLastName2: "Guarantor's last name",
    responsibleFirstName2: "Guarantor's first name",
    responsibleParty: 'Responsible Party',
    secondaryInsuranceCheck: 'Tienes un seguro secundario',
    self: 'Self',
    signature: 'Type your name to sign',
    ssn: 'SSN',
    state: 'State of service',
    subscriberId: 'Member ID',
    subscriberId2: 'Member ID',
    zipCode: 'ZIP code',
    zipCodePharmacySearch: 'ZIP Code Pharmacy search',
    yes: 'Yes',
    no: 'No',
    fromRangeDate: 'From',
    fromRangeDateA: 'Ordered date from',
    toRangeDate: 'To',
    covered: 'Covered',
    notCovered: 'No active insurance found',
    patientBirthdate: 'Date of birth',
    pharmacyName: 'Pharmacy name',
    noPharmacy:'I don`t have a pharmacy of my preference',
    zipPharmacy:'ZIP code pharmacy search', 
    selectPharmacy:'Select your preferred pharmacy',
    errorIsAuth:'This field is required.',
    mobile: 'Mobile',
    newEntry: 'Create new entry',
    enter: 'Enter',
    preferredDate: 'Preferred date',
    PreferredHour: 'Preferred hour',
    nameHolderInsured: 'Name of holder insured',
    placeholderNameHolderInsured: 'Enter name of holder insured',
    lastnameHolderInsured: 'Last name of holder insured',
    placeholderLastnameHolderInsured: 'Enter last name of holder insured',
    birthHolderInsured: 'Date of birth of holder insured',
    placeholderBirthHolderInsured: 'Month/Day/Year',
  },
  es: {
    accountNumber: 'Número de cuenta Sanitas',
    address: 'Dirección (apto, etc.)',
    birthdate: 'Fecha de nacimiento',
    city: 'Ciudad',
    companyId: 'ID de la compañía',
    date: 'mm/ dd/ aaaa',
    dateLabel: 'Fecha de registro',
    documents: 'Documento (emancipación, ID)',
    documentTypes: '*Formatos permitidos: JPG, PDF, PNG, DOC, HEIC',
    email: 'Correo electrónico',
    mail: 'Correo electrónico',
    firstName: 'Primer nombre',
    groupId: 'ID del grupo',
    groupId2: 'ID del grupo',
    guarantor: 'Tutor legal (Representante del paciente)',
    guarantorBirthdate: 'Fecha de nacimiento del representante',
    guarantorReason: 'Razón del representante',
    guarantorSsn: 'SSN del representante',
    garantorSsnLabel: 'SSN del representante',
    selfSsn: 'Mi SSN',
    insuranceCompany: 'Compañía aseguradora',
    insuranceInfo2nd: 'Información del seguro secundario',
    insuredLastName: 'Apellido del asegurado principal',
    insuredLastName2: 'Apellido del asegurado',
    insuredName: 'Nombre del asegurado principal',
    insuredName2: 'Nombre del asegurado ',
    lastName: 'Apellido',
    homePhone: 'Teléfono casa',
    movilPhone: 'Celular',
    movilNumber: 'Celular',
    textAddress: 'Dirección',
    sex: 'Género al nacer',
    sexF: 'Femenino',
    sexM: 'Masculino',
    sexD: 'Desconocido',
    dateOfBirth: 'Fecha de nacimiento',
    btnUpdateInformation: 'Actualizar información',
    btnReturnInformation: 'Atras',
    titleAlertConfirm: 'Estas seguro de que quieres guardar estos cambios ?',
    titleSuccessConfirm: "Su informacion de contacto ha sido actualizada. La información actualizada se verá reflejada mañana. Gracias.",
    btnTitleSuccessConfirm: "Aceptar",        
    maritalStatus: 'Estado civil',
    maxMbSize: 'Tamaño máximo: 3MB',
    optional: 'opcional',
    password: 'Contraseña',
    patientRelationship: 'Relación del paciente y el asegurado',
    patientRelationship2nd: 'Relación del paciente y el asegurado',
    patientRelationship2nd2: 'Relación del paciente y el asegurado',
    phoneNumber: 'Número de teléfono',
    relationshipToInsured: 'Relación del paciente y el asegurado',
    relationshipToInsured2nd: 'Relación del paciente y el asegurado',
    responsibleFirstName: 'Nombre del representante',
    responsibleLastName: 'Apellido del representante',
    responsibleLastName2: 'Apellido del representante',
    responsibleFirstName2: "Nombre del representante",
    responsibleParty: 'Parte Responsable',
    secondaryInsuranceCheck: 'Tienes un seguro secundario',
    self: 'Paciente',
    signature: 'Escribe tu nombre para firmar',
    ssn: 'SSN',
    state: 'Estado de servicio',
    subscriberId: 'Id del suscriptor',
    subscriberId2: 'Id del suscriptor',
    zipCode: 'Código postal',
    zipCodePharmacySearch: 'Código postal Búsqueda de farmacias',
    yes: 'Si',
    no: 'No',
    fromRangeDate: 'Desde',
    fromRangeDateA: 'Fecha de orden Desde',
    toRangeDate: 'Hasta',
    covered: 'Activo',
    notCovered: 'No se encontró el seguro activo',
    patientBirthdate: 'Fecha de nacimiento del paciente',
    pharmacyName: 'Nombre de farmacia',
    noPharmacy:'No tengo una farmacia de mi preferencia',
    zipPharmacy:'Código ZIP de farmacia',
    selectPharmacy:'Selecciona tu farmacia',
    errorIsAuth:'Este campo es requerido.',
    mobile: 'Celular',
    newEntry: 'Crear nueva entrada',
    enter: 'Siguiente',
    preferredDate: 'Fecha preferida',
    PreferredHour: 'Hora preferida',
    nameHolderInsured: 'Nombre del titular del seguro',
    placeholderNameHolderInsured: 'Ingresa el nombre del titular',
    lastnameHolderInsured: 'Apellido del titular del seguro',
    placeholderLastnameHolderInsured: 'Ingresa el apellido del titular',
    birthHolderInsured: 'Fecha de nacimiento del titular del seguro',
    placeholderBirthHolderInsured: 'Mes/Dia/Año',
  },
};

export default nsFormLabel;
