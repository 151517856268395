/**
###### toLocaleString ######

        - dateStyle: "full", "long", "medium", "short"
        - timeStyle: "full", "long", "medium", "short"
        - localeMatcher: "best-fit" (default), "lookup"

    timeZone
        - hour12: false, true
        - hourCycle: "h11", "h12", "h23", "h24"
        - formatMatcher: "basic", "best-fit" (default)
        - weekday: "long", "short", "narrow"
        - year: "2-digit", "numeric"
        - month: "2-digit", "long", "narrow", "numeric", "short"
        - day: "2-digit", "numeric"
        - hour: "2-digit", "numeric"
        - minute: "2-digit", "numeric"
        - second: "2-digit", "numeric"
        - timeZoneName: "long", "short"
*/
import i18n from 'i18next';
import { string } from 'yup/lib/locale';

type Format = "2-digit" | "long" | "narrow" | "numeric" | "short";
const getMonthName = (date: string | undefined, format: Format ): string => {

    if(!date) return '';

    const dateConverted = new Date(date);
    const month = dateConverted.toLocaleString(i18n.language ?? 'default', { month: format });

    return month;

}

const getDateAsText = (date: string | undefined, format: Format): string => {

    if(!date) return '';

    const month = getMonthName(date, format)
    const newDate = new Date(date);
    return `${month} ${newDate.getDate()}${i18n.language === 'en' ? ordinalText(newDate.getDate()) : ''}, ${newDate.getFullYear()}`
}

const ordinalText = (day: number) => {
    if( day % 10 === 1 ) return 'st';
    if( day === 2 || day === 22 ) return 'nd';
    if( day === 3 || day === 23 ) return 'rd';
    if( day >= 4 && day <= 20 || day >= 24 && day <= 30 ) return 'th';

    return '';
}

const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
// const EasternTime = 'America/New_York';

interface CurrentDateProps {
    date: string,
    timeZone?: TimeZone,
    language?: string
}

interface TimeZone {
    timeZone: string,
    key?: string
}

const getCustomDate = ({date, timeZone = {timeZone: currentTimeZone, key: ''}, language = 'en'}: CurrentDateProps) => {

    let originalDate = new Date(date);

    const finalDate = originalDate.toLocaleString(`${language}-US`, {
      timeZone: timeZone.timeZone,
      year: 'numeric',
      month: 'numeric',//'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      timeZoneName: 'short',
      hour12: true,
    });

    const finalDate2 = originalDate.toLocaleString('en-US', {
        timeZone: timeZone.timeZone,
    });

    const finalDateSplited = finalDate.replace(',', '').split(' ');

    if(language === 'es') {

        const customDate = finalDateSplited[0].split('/')

        const day = parseInt(customDate[0]) > 9 ? customDate[0] : `0${customDate[0]}`;
        const month = parseInt(customDate[1]) > 9 ? customDate[1] : `0${customDate[1]}`

        return {
            date: `${month}/${day}/${customDate[2]}`,
            time: `${finalDateSplited[1]} ${finalDateSplited[2]} ${timeZone?.key ?? ''}`,
            dateToValidate: finalDate2
        };

    } else {

        const customDate = finalDateSplited[0].split('/')

        const month = parseInt(customDate[0]) > 9 ? customDate[0] : `0${customDate[0]}`;
        const day = parseInt(customDate[1]) > 9 ? customDate[1] : `0${customDate[1]}`

        return {
            date: `${month}/${day}/${customDate[2]}`,
            time: `${finalDateSplited[1]} ${finalDateSplited[2]} ${timeZone?.key ?? ''}`,
            dateToValidate: finalDate2
        };

    }

}

const getHourByTimeZone = (timeZone: string) => {
    const date = new Date();

    // create a DateTimeFormat object with specific options
    const dateFormat = new Intl.DateTimeFormat("en-US", {
        timeZone,
        timeZoneName: "short",
        year: 'numeric',
        month: '2-digit',//'long',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
    }).formatToParts(date).reduce((acc, part) => {
        acc[part.type] = part.value;
        return acc;
    }, {
        day: string,
        hour: string,
        literal: string,
        minute: string,
        month: string,
        timeZoneName: string,
        year: string,
    });

    return dateFormat;
}

export {
    getMonthName,
    getDateAsText,
    getCustomDate,
    getHourByTimeZone
}
