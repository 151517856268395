import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import appConfig from 'config';
import { Row, Column, IconLinkRow, Card, CenteredWrapper } from '../../../components/templates';
import { KeraltyButton, KeraltyText, Image } from '../../../components/atoms';
import { SectionWidget } from 'app/components/molecules';
import styled from '../../../ui-core/styled-components';
import BREAKPOINTS from '../../../ui-core/utils/breakpoints';
import arrowAltCircleLeftIcon from '../../../assets/icons/arrow-alt-circle-left.svg';
import userChatIcon from '../../../assets/icons/user-md-chat.svg';
import laptopMedical from '../../../assets/icons/laptop-medical.svg';
import userCallIcon from '../../../assets/icons/user-md-call.svg';
import { utcTime } from 'app/ui-core/utils/times';

import ROUTES from '../../../ui-core/utils/routes';

import {
	closeSymptomChecker,
	openSymptomChecker,
	showChat,
} from 'adapter/chat/chatSlice';
import { useAppDispatch, useAppSelector } from 'adapter/hooks';
import useAuth from '../../../hooks/useAuth';
import { shutdown, } from 'adapter/sanitasChat/sanitasChat';

import i18n from "i18next";
import { capitalizeJustFirst } from 'app/ui-core/utils/helpers/format';
import { userSelectors } from 'adapter/user/userSelectors';
import useIsMobile from 'app/hooks/useIsMobile';
import { disableToTN, ScheduleCode } from 'app/ui-core/utils/disableOptions';
import AuthRepository from 'infrastructure/keraltyApi/repositories/auth'
import { useEffect, useState, useCallback } from 'react';

import ModalTelevisitPay from 'app/components/organisms/getCare/televisitPayment/modals/ModalTelevisitPay';
import ModalInsuranceNoValidated from 'app/components/organisms/getCare/televisitPayment/modals/ModalInsuranceNoValidated';
import ModalInsuranceInactived from 'app/components/organisms/getCare/televisitPayment/modals/ModalInsuranceInactived';
import ModalPCPNoSanitas from 'app/components/organisms/getCare/televisitPayment/modals/ModalPCPNoSanitas';
import ModalPreviousTransaction from 'app/components/organisms/getCare/televisitPayment/modals/ModalPreviousTransaction';
import ModalTransactionProcess from 'app/components/organisms/getCare/televisitPayment/modals/ModalTransactionProcess';
import ModalNoNewPayment from 'app/components/organisms/getCare/televisitPayment/modals/ModalNoNewPayment';
import { IInsurancePaymentResponse, ISessionInformation, OtherValue } from 'domain/entities/paymentInfo';
import * as sanitasAdapterMethods from '../../../../adapter/sanitasChat/sanitasChat';
import { initPayment, createNewTransactionBase, checkTransactionStatus, cancelUserTransaction, validateInsurance } from 'adapter/payment/paymentSlice';
import { SERVICES_TYPES, PaymentCode, SESSION_STATUS, defaultValue, VARIABLE_TYPES, EXPIRATION_TIMES } from '../../../../domain/valueObjects/payment';
import { selectPaymentLoading } from 'adapter/payment/paymentSelectors';
import Loader from '../../../components/templates/loader';
import useChatProvider from 'app/hooks/useChatProvider';
import ModalInsuranceValidate from 'app/components/molecules/ModalInsuranceValidate';
import ModalNoCopayment from './televisitPayment/modals/ModalNoCopayment';
import { KeraltySWS } from 'app/hooks/useKeraltySWS';
import { SESSION_STORAGE_ITEMS } from 'app/ui-core/utils/constants';

const { MAIN, GET_CARE } = ROUTES;

// const sizeIconBackButton: number = 1;
const sizesUserChatIcon: IconSizes = { width: 3.58, height: 2.88 };
const sizesTermometerGreenIcon: IconSizes = { width: 2.88, height: 2.88 };
const sizesLaptopMedicalIcon: IconSizes = { width: 2.8, height: 2.24 };

const linkToTelevisit: string = appConfig.TELADOC_URL;


interface IconSizes {
	width: number;
	height: number;
}

const RightContent = styled(Row)`
	display: flex;
	flex-direction: column;
	flex: 100%;
	@media (min-width: ${BREAKPOINTS.minPhoneWidth}) and (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
		margin-top: 5vh;
		padding-inline: 10px;
	}
`;


const TitleContiner = styled(Column)`
	h2 {
		margin-top: 0rem;
	}
`;

const LeftColumn = styled(Column)`
	justify-content: flex-end;
	padding: 0rem;

	@media screen and (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
		justify-content: center;
	}
`;

const RightColumn = styled(Column)`
	justify-content: flex-start;
	padding: 0rem;

	@media screen and (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
		justify-content: center;
	}
`;
const CenterColumn = styled(Row)`
    align-items: flex-end;
	padding: 0rem;

	@media screen and (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
		justify-content: center;
	}
`;

const GetCareNowButton = styled(KeraltyButton)`
  box-shadow: 0rem 0.166rem 0.33rem 0rem #bbbcbd;
  min-height: 12.75rem;
  height: auto;
	width: 22vw;
	margin: 1.5rem 1.455rem;
	border-radius: 0.33rem;
  padding: 2.34rem 0.6rem !important;

	h2 {
		margin: 0rem;
		font-size: 1.34rem;
		line-height: 1.34rem;
	}
	p {
		margin: 0rem;
		font-size: 1rem;
		line-height: 1.17rem;
		color:gray;
		text-transform: none;
		font-weight: 400;

	}

	@media screen and (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
	}
`;

const DisabledGetCareNowOptionContent = styled.div<{ backgroundImage: string }>`
	width: 280px;
	min-height: 171px;
	height: auto;
	background: rgba(255, 255, 255, 0.75);
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 10px;
	background-image: url(${({ backgroundImage }) => backgroundImage});
	background-repeat: no-repeat;
	background-position: center;
	background-size: 40%;
	margin-top: 0rem;
	margin-bottom: 2.5rem;
	margin-left: 1.46rem;
	margin-right: 2.5rem;
	@media screen and (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
		margin-top: 2rem;
		margin-left: 0;
		margin-right: 0;
		width: 100%;
	}
`;

const BlurContent = styled.div`
	width: 100%;
	min-height: 171px;
	background-color: rgba(255, 255, 255, 0.75);
	border-radius: 5px;
	backdrop-filter: blur(2.5px);
	padding-top: 10px;
`;

const NoteContent = styled.div<{ language: string }>`
	width: ${({ language }) => language === 'es' ? '75%' : '50%'};
	height: 26px;
	background: #0269A7;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
	border-radius: 0px 30px 28px 0px;
	font-style: normal;
	font-weight: 600;
	line-height: 20px;
	display: flex;
	align-items: center;
	text-align: center;
	letter-spacing: 1px;
	color: #FFFFFF;
	justify-content: center;
`;

const Note = styled.span<{ isSafari: boolean }>`
	letter-spacing: 1px;
	margin-left: 10px;
	font-size:  ${({ isSafari }) => isSafari ? '10px' : '12px'};
`;

const DisabledGetCareNowOptionContentTN = styled.div`
	width: 288.43px;
	min-height: 171px;
	height: auto;
	background: rgba(255, 255, 255, 0.75);
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 10px;
	background-repeat: no-repeat;
	background-position: center;
	background-size: 40%;
	margin-top: 0rem;
	margin-bottom: 2.5rem;
	margin-left: 2.5rem;
	margin-right: 1.46rem;
	margin-top:10px;
	display: flex;
    flex-direction: column;
    align-items: center;
	@media screen and (max-width: ${BREAKPOINTS.maxPhoneWidth}) {
		margin-top: 2rem;
		margin-left: 0;
		margin-right: 0;
	}

`;

const NoteContentTN = styled.div`
	width: 100%;
	min-height: 26px;
	background: #0269A7;
	box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.25);
	border-radius: 10px 10px 0px 0px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #FFFFFF;
`;

const NoteTN = styled.span<{ isSafari: boolean }>`
	font-style: normal;
	font-weight: 600;
	font-size: ${({ isSafari }) => isSafari ? '9px' : '11px'};
	line-height: 16px;
	text-transform: uppercase;
	@media screen and (min-width: 599px) and (max-width: 693px) {
		font-size: ${({ isSafari }) => isSafari ? '8px' : '9px'};
	}
`;

const ImgTN = styled.img`
	width: ${sizesUserChatIcon.width}rem;
	height: ${sizesUserChatIcon.height}rem;
	margin-top: 27.32px;
`;

const CardTN = styled(Card)`
	padding: 0px !important;
	cursor: none !important;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
`;

const SectionWidgetTN = styled.div`
	display: flex;
	flex-direction: row;
	height: 100%;
	width: 100%;
`;


const DisabledGetCareNowOption = ({ title, subtitle, backgroundImage }) => {

	const { t } = useTranslation();
	const language = i18n.language.includes('en') ? 'en' : 'es';
	const isMobile = useIsMobile();
	const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
	return (
		<DisabledGetCareNowOptionContent backgroundImage={backgroundImage}>
			<BlurContent>
				<NoteContent language={language}>
					<Note isSafari={isSafari}>
						{t('button.comingSoon')}
					</Note>
				</NoteContent>
				<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
					<KeraltyText
						type="title"
						fontWeight={'700'}
						color='#3F3F3F'
						fontSize='20px'
						style={{ lineHeight: '25px', marginBottom: 2, width: language === 'es' ? '100%' : isMobile ? '25rem' : '20rem' }}
					>
						{title}
					</KeraltyText>
				</div>

				<KeraltyText
					type="title"
					fontWeight={'700'}
					color='#414141'
					fontSize='14px'
					style={{ lineHeight: '17px', marginBottom: 0 }}
				>
					{t('button.beAble')}
				</KeraltyText>
				<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

					<KeraltyText
						type="title"
						fontWeight={'400'}
						color='#414141'
						fontSize='14px'
						style={{ lineHeight: '17px', marginTop: 2, width: language === 'es' ? '100%' : isMobile ? '23rem' : '19rem' }}
					>
						{subtitle}
					</KeraltyText>
				</div>
			</BlurContent>
		</DisabledGetCareNowOptionContent>
	)
}

// Time zone card
const DisabledGetCareSchedule = ({ title, subtitle, Image }) => {

	const { t } = useTranslation();
	const language = i18n.language.includes('en') ? 'en' : 'es';
	const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

	return (
		<DisabledGetCareNowOptionContentTN >
			<NoteContentTN>
				<NoteTN isSafari={isSafari}>
					{t('button.schedule')}
				</NoteTN>
			</NoteContentTN>
			<ImgTN
				src={Image}
			/>

			<KeraltyText
				type="title"
				fontWeight={'600'}
				color='#002D57'
				fontSize='18px'
				style={{ lineHeight: '16px' }}
			>
				{title}
			</KeraltyText>
			<KeraltyText
				type="title"
				fontWeight={'400'}
				color='#747474'
				fontSize='12px'
				style={{ lineHeight: '14px', marginTop: -2, width: language === 'es' ? '21rem' : '16rem' }}
			>
				{subtitle}
			</KeraltyText>
		</DisabledGetCareNowOptionContentTN>
	)
}


const GetCareNowPage = () => {
	const dispatch = useAppDispatch();
	const { t } = useTranslation();
	const { goBack, push, replace } = useHistory();
	const { user } = useAuth();
	const language = i18n.language.includes('en') ? 'en' : 'es';
	const chatState = useAppSelector((state) => state.chat.symptomChecker);
	const isVAOpen = useAppSelector(({ chat }) => chat.currentInstance === 'virtualAssistant');
	const isHLOpen = useAppSelector(({ chat }) => chat.currentInstance === 'healtLibrary');
	const isChatOpen = chatState.isOpen;
	const sanitasChatState = useAppSelector((state) => state.sanitasChat);
	const { sanitasState } = sanitasChatState;
	const isActiveCoverage = useAppSelector(userSelectors.selectActiveCoverage);
	const isMobile = useIsMobile();
	const [isHint, setIsHint] = useState(false)
	const [sessionId, setSessionId] = useState('')
	const [paymentResponse, setPaymentResponse] = useState<IInsurancePaymentResponse>(defaultValue)
	const { token, authUid, state } = useAppSelector(state => state.user)
	const isLoading = useAppSelector(selectPaymentLoading);
	const [isAvailable, setIsAvailable] = useState<boolean>(true)

	const [paymentError, setPaymentError] = useState<boolean>(false)

	const { handleChatWithDoc, currentChatStatus, closeModalChatStatus, openChat } = useChatProvider();

	const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

	const handleSymptomChecker = () => {
		dispatch(shutdown());
		if (isVAOpen || isHLOpen) dispatch(closeSymptomChecker());
		if ((!isChatOpen && !chatState.isInitialized) || isVAOpen) {
			dispatch(openSymptomChecker({
				isOpen: true,
				isPublic: false,
				lng: language,
				userInfo: {
					dob: user.birthdate,
					gender: user.sex,
					memberid: user.idEcw,
					organization_user_id: user.idEcw,
				},
			}));
		} else {
			dispatch(showChat());
		}
	}

	const handleTelevisit = () => {

		dispatch(initPayment({
			authUid,
			state: state.toUpperCase(),
			token,
			service: SERVICES_TYPES.VIDEO_CALL,
			english: language === 'en'
		}))
			.unwrap()
			.then((response: IInsurancePaymentResponse) => {

				if (response.code === PaymentCode.transaction_completed) {
					window.open(linkToTelevisit, '_blank')
					return;
				}

				setPaymentResponse(response)

			})

	};

	const closeModal = () => {
		setPaymentError(false);
		setPaymentResponse(defaultValue)
	};



	const triggerPatientSupport = useCallback(async () => {
		openChat({ queue: 'support' })
		closeModal();
		closeModalChatStatus();
	}, [dispatch]);

	const createTransactionBase = async () => {

		dispatch(createNewTransactionBase({
			authUid,
			state: state.toUpperCase(),
			token,
			service: SERVICES_TYPES.VIDEO_CALL,
			english: language === 'en'
		}))
			.unwrap()
			.then((response: IInsurancePaymentResponse) => {

				setPaymentResponse({
					...paymentResponse,
					transactionId: response.id,
					code: PaymentCode.modal_televisit_pay
				})

			})

	};
	const createTransactionBaseOnly = async () => {

		dispatch(createNewTransactionBase({
			authUid,
			state: state.toUpperCase(),
			token,
			service: SERVICES_TYPES.VIDEO_CALL,
			english: language === 'en'
		}))
			.unwrap()
			.then((response: IInsurancePaymentResponse) => {

			})

	};

	const createNewTransaction = async (newTransaction: boolean) => {

		if (paymentResponse.code === PaymentCode.transaction_in_process) {

			const sessionUrl = paymentResponse.otherValues?.find(
				(value: OtherValue) => value.variable === VARIABLE_TYPES.SESSION_URL
			);

			if (!sessionUrl?.value) {

				dispatch(cancelUserTransaction({
					authUid,
					state,
					service: SERVICES_TYPES.VIDEO_CALL
				}))
					.unwrap()
					.then((response: any) => {
						createTransactionBase();
					}).catch((error: any) => { })

				return;
			}

			const sessionId = paymentResponse.otherValues?.find(
				(value: OtherValue) => value.variable === VARIABLE_TYPES.SESSION_ID
			);

			if (!sessionId?.value) return;

			setSessionId(sessionId.value)

			validateSessionStatus(newTransaction, sessionId.value);

			return;

		}

		const finalDate = new Date(paymentResponse.transactionCreatedDate);
		finalDate.setMinutes(finalDate.getMinutes() + EXPIRATION_TIMES.PAYMENT_CREATED)

		const currentDate = new Date();

		if (currentDate > finalDate || newTransaction) closeTransaction();

		setPaymentResponse({
			...paymentResponse,
			code: PaymentCode.modal_televisit_pay
		})

	};

	const validateSessionStatus = (newTransaction: boolean, sessionId: any) => {
		dispatch(checkTransactionStatus(sessionId))
			.unwrap()
			.then((response: ISessionInformation) => {

				if (newTransaction && response.transactionInformation.status !== SESSION_STATUS.IN_PROCESS) {
					setPaymentResponse({
						...paymentResponse,
						code: PaymentCode.cant_continue_with_a_new_payment
					})
					return;
				} else if (!newTransaction && response.transactionInformation.status !== SESSION_STATUS.IN_PROCESS) {
					replace(`/${MAIN.getCare}/${GET_CARE.paymentStatus}?${VARIABLE_TYPES.SESSION}=${sessionId}`)
					return;
				}

				if (newTransaction) closeTransaction();

				setPaymentResponse({
					...paymentResponse,
					code: PaymentCode.modal_televisit_pay
				})

			})
	}

	const closeTransaction = () => {

		dispatch(cancelUserTransaction({
			authUid,
			state: state.toUpperCase(),
			service: SERVICES_TYPES.VIDEO_CALL
		}))
			.unwrap()
			.then((response: any) => {
				handleTelevisit();
			}).catch((error: any) => { })

		return;
	}
	const handleNoCopaymentValue = () => {
		createTransactionBaseOnly();
		window.open(linkToTelevisit, '_blank')
		closeModal();
		return;

	}

	const isModalDisplayed = (paymentCode: PaymentCode): boolean => !isLoading && paymentResponse && paymentResponse.code === paymentCode

	useEffect(() => {
		const callTelevisit = KeraltySWS?.swInstance?.getItem(SESSION_STORAGE_ITEMS.HANDLE_TELEVISIT);

		if (callTelevisit === 'true') {
			KeraltySWS?.swInstance?.deleteItem(SESSION_STORAGE_ITEMS.HANDLE_TELEVISIT);
			handleTelevisit();
		};

		KeraltySWS?.swInstance?.deleteItem(SESSION_STORAGE_ITEMS.PAYMENT_IN_PROGRESS)
	}, [])

	useEffect(() => {
		if (!isActiveCoverage) {
			const fetchValidation = async () => {
				const hintStatus = await AuthRepository.hintValidation(user?.authUid, user.state)
				setIsHint(hintStatus)
			}
			fetchValidation()
				.catch(console.error);
		}
	}, [])



	const diferenceTimeByZone = [
		{
			state: 'FL',
			diference: -4
		},
		{
			state: 'TN',
			diference: -5
		},
	];

	/* useEffect(() => {
		 const originalDate = new Date();

		 const converted = originalDate.toISOString();

		 const timeConverted = converted.split('T')[1].split(':');

		 const newDate = new Date(
		 `${converted.split('T')[0]} ${timeConverted[0]}:${timeConverted[1]}:${
			   timeConverted[2].split('.')[0]
		 }`
		 );

		 newDate.setHours(
			   newDate.getHours() + (user.state ? diferenceTimeByZone.find((i) => i.state === user.state)!.diference : 0)
		 );
		 if ( disableToTN(user?.state)) {
			   if (newDate.getDay() !== ScheduleCode.startDay && newDate.getDay() !== ScheduleCode.endDay) {
				   if (newDate.getHours() >= ScheduleCode.startTime && newDate.getHours() < ScheduleCode.endTime) {
					   setIsAvailable(true);
				   } else {
					   setIsAvailable(false);
				   }
			   } else {
				   setIsAvailable(false);
			   }
		 } else{
			   setIsAvailable(true);
		 }
	}, [new Date().getHours()])

*/
	return (
		<>


			{isLoading && <Loader />}
			{/* Payment modals */}
			{isModalDisplayed(PaymentCode.modal_televisit_pay) && (
				<ModalTelevisitPay close={closeModal} paymentError={paymentError} setPaymentError={setPaymentError} paymentInfo={paymentResponse} />
			)}
			{isModalDisplayed(PaymentCode.modal_insurance_not_validated) && (
				<ModalInsuranceNoValidated
					close={closeModal}
					paymentInfo={paymentResponse}
					triggerPatientSupport={triggerPatientSupport}
					createTransactionBase={createTransactionBase}
				/>
			)}
			{isModalDisplayed(PaymentCode.modal_insurance_inactived) && (
				<ModalInsuranceInactived
					close={closeModal}
					paymentInfo={paymentResponse}
					triggerPatientSupport={triggerPatientSupport}
					createTransactionBase={createTransactionBase}
				/>
			)}
			{isModalDisplayed(PaymentCode.modal_pcp_no_validated) && (
				<ModalPCPNoSanitas
					close={closeModal}
					paymentInfo={paymentResponse}
					triggerPatientSupport={triggerPatientSupport}
					createTransactionBase={createTransactionBase}
				/>
			)}
			{isModalDisplayed(PaymentCode.previous_transaction) && (
				<ModalPreviousTransaction createNewTransacion={createNewTransaction} />
			)}
			{isModalDisplayed(PaymentCode.impossible_to_create_new_payment) && (
				<ModalTransactionProcess close={closeModal} />
			)}
			{isModalDisplayed(PaymentCode.transaction_in_process) && (
				<ModalPreviousTransaction createNewTransacion={createNewTransaction} />
			)}
			{isModalDisplayed(PaymentCode.cant_continue_with_a_new_payment) && (
				<ModalNoNewPayment sessionId={sessionId} />
			)}
			{isModalDisplayed(PaymentCode.unknown_value_co_insurance) && (
				<ModalNoCopayment handleNoCopaymentValue={handleNoCopaymentValue} close={closeModal} />
			)}

			{/* Chat with a Doctor modals */}

			<ModalInsuranceValidate
				currentChatStatus={currentChatStatus}
				closeModalChatStatus={closeModalChatStatus}
				externalAction={closeModal}
			/>

			<RightContent fullHeight>
				<IconLinkRow
					onClick={goBack}
					color="accent"
					icon={arrowAltCircleLeftIcon}
					iconAlt={t('imgNicons.backArrow')}
					text={t('routes.back')}
				/>
				<Row>
					<TitleContiner lg={12}>
						<KeraltyText align={isMobile ? 'left' : 'center'} type="title">
							{t('getCare.getCareNow')}
						</KeraltyText>
					</TitleContiner>
				</Row>
				<Row style={{ padding: isMobile ? '0px' : '0px 1rem' }}>
					<Column
						lg={0}
						md={0}
						sm={12}
						style={{ display: 'flex', justifyContent: 'center', height: '11.5rem' }}
					>
						<CenteredWrapper>
							<Card onClick={handleChatWithDoc}>
								<SectionWidget
									img={userChatIcon}
									text={t('button.chatWithaDoctor')}
									description={capitalizeJustFirst(t('button.chatWithaDoctorText'))}
									alt={t('imgNicons.chatDoctorIcon')}
								/>
							</Card>
						</CenteredWrapper>

						{/* Time zone card */}
						{/* <CenteredWrapper>
              <CardTN>
                <NoteContentTN>
                  <NoteTN isSafari={isSafari}>{t('button.schedule')}</NoteTN>
                </NoteContentTN>
                <SectionWidgetTN>
                  <div
                    style={{
                      width: '18%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Image
                      src={userChatIcon}
                      width={2.8}
                      height={2.3}
                      alt={t('imgNicons.chatDoctorIcon')}
                    />
                  </div>
                  <div style={{ width: '82%' }}>
                    <KeraltyText
                      align="left"
                      type="title"
                      fontWeight={'400'}
                      color="#002D57"
                      fontSize="15px"
                      style={{ lineHeight: '19px', marginTop: 22 }}
                    >
                      {t('button.chatWithaDoctor')}
                    </KeraltyText>
                    <KeraltyText
                      align="left"
                      type="title"
                      fontWeight={'600'}
                      color="#747474"
                      fontSize="12px"
                      style={{
                        lineHeight: '15px',
                        marginTop: -12,
                        width: language === 'es' ? '23rem' : '20rem',
                      }}
                    >
                      {t('button.chatWithaDoctorText')}
                    </KeraltyText>
                  </div>
                </SectionWidgetTN>
              </CardTN>
            </CenteredWrapper> */}

					</Column>

					<LeftColumn lg={6} md={6} sm={0}>
						<GetCareNowButton color="light" squareRadius={true} onClick={handleChatWithDoc}>
							<span>
								<Image
									src={userChatIcon}
									width={sizesUserChatIcon.width}
									height={sizesUserChatIcon.height}
									alt={t('imgNicons.chatDoctorIcon')}
								/>
								<KeraltyText
									type="title"
									fontWeight={'400'}
									style={{ paddingBottom: 10, fontWeight: 'normal' }}
									noTextTransform
								>
									{t('button.chatWithaDoctor')}
								</KeraltyText>
								<KeraltyText type="paragraph">{t('button.chatWithaDoctorText')}</KeraltyText>
							</span>
						</GetCareNowButton>

						{/* Time zone card */}
						{/* {!isAvailable &&<DisabledGetCareSchedule
                title={t('button.chatWithaDoctor')}
                subtitle={t('button.chatWithaDoctorText')}
                Image={userChatIcon}
              />} */}
					</LeftColumn>
					<Column lg={0} md={0} sm={12} style={{ display: 'flex', justifyContent: 'center' }}>
						{!isAvailable && <CenteredWrapper>
							<CardTN>
								<NoteContentTN>
									<NoteTN isSafari={isSafari}>{t('button.schedule')}</NoteTN>
								</NoteContentTN>
								<SectionWidgetTN>
									<div
										style={{
											width: '18%',
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center',
										}}
									>
										<Image
											src={laptopMedical}
											width={2.8}
											height={2.3}
											alt={'televisit'}
										/>
									</div>
									<div style={{ width: '82%' }}>
										<KeraltyText
											align="left"
											type="title"
											fontWeight={'400'}
											color="#002D57"
											fontSize="15px"
											style={{ lineHeight: '19px', marginTop: 22 }}
										>
											{t('button.televisit')}
										</KeraltyText>
										<KeraltyText
											align="left"
											type="title"
											fontWeight={'600'}
											color="#747474"
											fontSize="12px"
											style={{
												lineHeight: '15px',
												marginTop: -12,
												width: language === 'es' ? '23rem' : '20rem',
											}}
										>
											{t('button.televisitText')}
										</KeraltyText>
									</div>
								</SectionWidgetTN>
							</CardTN>
						</CenteredWrapper>}
						{isAvailable && <CenteredWrapper>
							<Card onClick={() => handleTelevisit()}>
								<SectionWidget
									img={laptopMedical}
									text={disableToTN(user?.state) ? t('button.televisitTN') :t('button.televisit')}
									description={capitalizeJustFirst(t('button.televisitText'))}
									alt={'televisit'}
								/>
							</Card>
						</CenteredWrapper>}
					</Column>
					<RightColumn lg={6} md={6} sm={0} noPadding>
						{isAvailable && <span onClick={() => handleTelevisit()}>
							<GetCareNowButton color="light" squareRadius={true}>
								<span>
									<Image
										src={laptopMedical}
										width={sizesLaptopMedicalIcon.width}
										height={sizesLaptopMedicalIcon.height}
									/>
									<KeraltyText
										type="title"
										fontWeight={'400'}
										style={{ paddingBottom: 10, fontWeight: '400' }}
										noTextTransform
									>
										{disableToTN(user?.state) ? t('button.televisitTN') :t('button.televisit')}
									</KeraltyText>
									<KeraltyText type="paragraph">{t('button.televisitText')}</KeraltyText>
								</span>
							</GetCareNowButton>
						</span>}
						{/* Time zone card */}
						{!isAvailable && <DisabledGetCareSchedule
							title={t('button.televisit')}
							subtitle={t('button.televisitText')}
							Image={laptopMedical}
						/>}
					</RightColumn>
				</Row>
				{user.state != 'TN' ? (
					<Column lg={0} md={0} sm={12}>
						<CenteredWrapper>
							<a
								href="tel:+18449624362"
								style={{ textDecoration: 'none', width: '100%', marginRight: 13 }}
							>
								<Card>
									<SectionWidget
										img={userCallIcon}
										text={t('button.callDoctor')}
										description={capitalizeJustFirst(t('button.callDoctorText'))}
										alt={'televisit'}
									/>
								</Card>
							</a>
						</CenteredWrapper>
					</Column>
				) : null}
				{window.innerWidth >= 600 && user.state != 'TN' ? (
					<div style={{ justifyContent: 'center', padding: '0px 1rem' }}>
						<a href="tel:+18449624362">
							<GetCareNowButton color="light" squareRadius={true}>
								<span>
									<Image
										src={userCallIcon}
										width={sizesUserChatIcon.width}
										height={sizesUserChatIcon.height}
									/>
									<KeraltyText
										type="title"
										fontWeight={'400'}
										style={{ paddingBottom: 10, fontWeight: 'normal' }}
										noTextTransform
									>
										{t('button.callDoctor')}
									</KeraltyText>
									<KeraltyText type="paragraph">{t('button.callDoctorText')}</KeraltyText>
								</span>
							</GetCareNowButton>
						</a>
					</div>
				) : null}
			</RightContent>
		</>
	);
};

export default GetCareNowPage;
