import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import KeraltySidebar, { SidebarProps } from '../../components/molecules/keraltySidebar';
import { ReactComponent as LabResultsIcon } from '../../assets/icons/lab-icon.svg';
import { ReactComponent as CareProgramsIcon } from '../../assets/icons/Icons-care-programs.svg';
import { ReactComponent as SymptomsCheckerIcon } from '../../assets/icons/termometer-icon.svg';
import { ReactComponent as AppoimentsIcon } from '../../assets/icons/appointments-icon.svg';
import { ReactComponent as ChatWithDocIcon } from '../../assets/icons/chatWithDocMini.svg';
import { ReactComponent as VideoCallDoctor } from '../../assets/icons/videoCallDoctor.svg';
import { ReactComponent as CallWithADocMini } from '../../assets/icons/callWithADocMini.svg';



import ROUTES from '../../ui-core/utils/routes';
import Appointments from './Appointments';
import GetCareNow from './GetCareNow';
import TalkToaDoctor from './TalkToaDoctor';
import CarePrograms from './CarePrograms';
import Televisit from './Televisit';
import ChatWithDoc from './ChatWithDoc';
import PaymentStatus from './PaymentStatus';
import { useHistory } from 'react-router-dom';

import Loader from '../../components/templates/loader';

import { closeSymptomChecker, openSymptomChecker, showChat } from 'adapter/chat/chatSlice';
import { useAppDispatch, useAppSelector } from 'adapter/hooks';
import useAuth from '../../hooks/useAuth';

import * as sanitasAdapterMethods from 'adapter/sanitasChat/sanitasChat';
import { selectmyHealthLoading } from 'adapter/myHealth/myHealthSelectors';

import i18n from 'i18next';
import { useState } from 'react';

const { MAIN, GET_CARE } = ROUTES;

const GetCareMain = () => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const language = i18n.language.includes('en') ? 'en' : 'es';
  const chatState = useAppSelector((state) => state.chat.symptomChecker);
  const isVAOpen = useAppSelector(({ chat }) => chat.currentInstance === 'virtualAssistant');
  const isHLOpen = useAppSelector(({ chat }) => chat.currentInstance === 'healtLibrary');
  const isChatOpen = chatState.isOpen;
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(selectmyHealthLoading);
  const { tab, subTab } = useParams();
  const history = useHistory();
  const [isGetCare, setIsGetCare] = useState(false)

  const userState = user?.state;

  const subTabDelegator = (subtab) => {
    let returnComponent: any;

    switch (subtab) {
      case GET_CARE.televisit:
        returnComponent = <Televisit />;
        break;
      case GET_CARE.talkToaDoctor:
        returnComponent = <TalkToaDoctor />;
        break;
      case GET_CARE.ChatWithDoc:
        returnComponent = <ChatWithDoc />;
        break;
    }

    return returnComponent;
  };

   const isItGetCare = () => {
    if (
      history.location.pathname == '/get-care/get-care-now/chat-with-doctor'
      || history.location.pathname == '/get-care/get-care-now/televisit'
      || history.location.pathname ==  '/get-care/chat-with-doctor'
      ||history.location.pathname =='/get-care/televisit'
      ) { return true } else { return false }

  }

  const sidebar: SidebarProps = {
    sideTabs: [

      {
        name: GET_CARE.ChatWithDoc,
        state: userState,
        icon: ChatWithDocIcon,
        text: isItGetCare()? t('getCare.ChatWithDoc.title'):'',
        content: <ChatWithDoc />,
        routeName: `${MAIN.getCare}/${GET_CARE.ChatWithDoc}`,
        showOnMobile: false,
      },
      {
        name: GET_CARE.televisit,
        state: userState,
        icon: VideoCallDoctor,
        text:isItGetCare()? t('button.televisit'):'',
        content: <Televisit />,
        routeName: `${MAIN.getCare}/${GET_CARE.televisit}`,
        showOnMobile: false,
      },
      {
        name: '',
        state: userState,
        icon: CallWithADocMini,
        showOnMobile: false,
        text:userState=='TN'?'':isItGetCare()? t('button.callDoctor'):'',
        callbackClick: () => window.location.href = "tel:+18449624362"
       
  
        ,
  
      },
  
    
  
      {
        name: GET_CARE.getCareNow,
        state: userState,
        icon: LabResultsIcon,
        text: !isItGetCare()? t('getCare.getCareNow'):'',
        content: subTab ? subTabDelegator(subTab) : <GetCareNow />,
        routeLabel: t('routes.getCare'),
        routeName: `${MAIN.getCare}/${GET_CARE.getCareNow}`,
        showOnMobile: false,
      },
      {
        name: GET_CARE.appointments,
        state: userState,
        icon: AppoimentsIcon,
        text:!isItGetCare()? t('getCare.appointments'):'',
        content: <Appointments />,
        routeName: `${MAIN.getCare}/${MAIN.appointments}`,
        showOnMobile: false,
      },
     /* {
        name: '',
        state: userState,
        icon: SymptomsCheckerIcon,
        showOnMobile: false,
        text:!isItGetCare()? t('getCare.symptomsChecker'):'',
        callbackClick: () => {
          dispatch(sanitasAdapterMethods.shutdown());
          if (isVAOpen || isHLOpen) dispatch(closeSymptomChecker());
          if ((!isChatOpen && !chatState.isInitialized) || isVAOpen || isHLOpen) {
            dispatch(
              openSymptomChecker({
                isOpen: true,
                isPublic: false,
                lng: language,
                userInfo: {
                  dob: user.birthdate,
                  gender: user.sex,
                  memberid: user.idEcw,
                  organization_user_id: user.idEcw,
                },
              })
            );
          } else {
            dispatch(showChat());
          }
        },
      },*/
      // {
      //   name: GET_CARE.carePrograms,
      //   state: userState,
      //   icon: CareProgramsIcon,
      //   text: !isItGetCare()? t('getCare.carePrograms'):'',
      //   content: <CarePrograms />,
      //   routeLabel: t('getCare.carePrograms'),
      //   routeName: `${MAIN.getCare}/${GET_CARE.carePrograms}`,
      //   showOnMobile: false,
      // },

      {
        name: GET_CARE.paymentStatus,
        state: userState,
        icon: LabResultsIcon,
        text: '',
        content: <PaymentStatus />,
        routeLabel: t('routes.getCare'),
        routeName: `${MAIN.getCare}/${GET_CARE.paymentStatus}`,
        showOnMobile: false,
      },
    ],

    routeLabel: t('routes.getCare'),
    activeByRoute: tab || GET_CARE.getCareNow,
  };

  return (
    <>
      {isLoading && <Loader />}
      <KeraltySidebar {...sidebar} hideMenu={(
        history.location.pathname == '/get-care'
        || history.location.pathname == '/get-care/appointments'
        || history.location.pathname == '/get-care/get-care-now'
        || history.location.pathname == '/get-care/appointments/upcoming-appointments'
        || history.location.pathname == '/get-care/appointments/previous-appointments'
        || history.location.pathname == `/${MAIN.getCare}/${GET_CARE.paymentStatus}`) ? true
        : false} />
    </>
  );
};

export default GetCareMain;
