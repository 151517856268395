import { configureStore, ThunkAction, Action, combineReducers } from '@reduxjs/toolkit';
import { CombinedState } from 'redux';
import { createBrowserHistory } from 'history';
import { connectRouter } from 'connected-react-router';
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER, } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import UserReducer, { persistConfig as userPersistConfig } from './user/userSlice';
import ChatReducer from './chat/chatSlice';
import MyHealthSlice from './myHealth/myHealthSlice';
import ForgotPassSlice from './forgotPassword/forgotPasswordSlice';
import MyAccountSlice from './myAccount/myAccountSlice';
import RegisterReducer, { persistConfig as registerPersistConfig, } from './register/registerSlice';
import PaymentSlice from './payment/paymentSlice';
import UndertandingYouAnxietySlice, { persistUnderstandingConfig } from './understandingAnxiety/understandingAnxiety.slice'

import IntercomChatReducer from './intercom/intercom';

import SanitasChatReducer from './sanitasChat/sanitasChat';
import unblockAccountSlice from './unblockAccount/unblockAccountSlice';
import twoFactorAuthenticationSlice, { persistTwoFactorConfig } from './twoFactorAuthentication/twoFactorAuthenticationSlice';
import { KeraltySWS } from 'app/hooks/useKeraltySWS';
import sessionStorage from 'redux-persist/lib/storage/session'
import session from 'redux-persist/lib/storage/session';
import { encryptTransform } from 'redux-persist-transform-encrypt';
import appConfig from 'config';

export const history = createBrowserHistory();

let persistSWS = sessionStorage

/*if(KeraltySWS && KeraltySWS.swInstance){
	persistSWS = KeraltySWS.swInstance.getAsyncInstance();
}else{
	persistSWS = sessionStorage
}*/
const persistConfig = {
	key: 'root',
	version: 1,
	storage: persistSWS,
	// Add your key here to persists the entire slice
	// If you need just a few values within the slice, use nested config instead
	whitelist: ['router'],
	transforms: [
		encryptTransform({
			secretKey: appConfig.KERALTY_SWS_KEY,
		  	onError: function (error) {
				// Handle the error.
		  	},
		}),
	],
	blacklist: ['chat', 'register']
	//Manual Persist can be used to start the persistence on user logged in
	// manualPersist: true
};

//TODO: fix this any later
const rootReducer: CombinedState<any> = combineReducers({
	router: connectRouter(history),
	user: persistReducer<any, any>(userPersistConfig, UserReducer),
	chat: ChatReducer,
	intercomChat: IntercomChatReducer,
	sanitasChat: SanitasChatReducer,
	myHealth: MyHealthSlice,
	forgotPassword: ForgotPassSlice,
	unblockAccount: unblockAccountSlice,
	register: persistReducer<any, any>(registerPersistConfig, RegisterReducer),
	myAccount: MyAccountSlice,
	payment: PaymentSlice,
	understandingYourAnxiety: persistReducer<any, any>(persistUnderstandingConfig, UndertandingYouAnxietySlice), /* UndertandingYouAnxietySlice*/
	twoFactorAuthentication: persistReducer<any, any>(persistTwoFactorConfig, twoFactorAuthenticationSlice) /* UndertandingYouAnxietySlice*/
});

const persistedReducer = persistReducer<ReturnType<typeof store.getState>, typeof store.dispatch>(
	persistConfig,
	rootReducer
);


export const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) => {
		const middleware = getDefaultMiddleware({
			// serializableCheck: false
			serializableCheck: {
				ignoredActions: [
					FLUSH,
					REHYDRATE,
					PAUSE,
					PERSIST,
					PURGE,
					REGISTER,
					'vim/openVim/fulfilled',
					'register/createAccount/rejected',
					'register/createAccount/pending',
					'register/createAccount/fullfilled',
					'register/setProp',
				],
			},
		})
		// if (process.env.NODE_ENV === 'development') {
		// 	console.warn('Activando Logger');
		// 	return middleware.concat(logger, routerMiddleware(history));
		// }
		// else
		return middleware;
	},
	devTools: process.env.NODE_ENV !== 'production',
});

export let persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
	ReturnType,
	RootState,
	unknown,
	Action<string>
>;
