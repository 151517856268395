enum GENDERS {
  male = 'male',
  female = 'female',
  unknown = 'unknown',
}

const TZ = [
	'America/New_York',
	'America/Chicago'
] as const

const TIMES_ZONES = {
  ET: {
    timeZone: TZ[0] as string,
    key: 'ET',
    states: ['FL']
  },
  CT: {
    timeZone: TZ[1] as string,
    key: 'CT',
    states: ['TN']
  },
};

const SPECIFIC_TIME_ZONES = [
	{
		timeZone: TZ[0] as string,
		prefixes: [ 'ET', 'EST' ]
	},
	{
		timeZone: TZ[1] as string,
		prefixes: [ 'CT', 'CST' ]
	}
]

const SESSION_STORAGE_ITEMS = {
  IN_MAINTENANCE: 'inMaintenance',
  UNDER_MAINTENANCE_MODAL: 'underMaintenanceModal',
  IS_PRIVATE: 'isPrivate',
  PERSIST_REGISTER: 'persist:register',
  PERSIST_USER: 'persist:user',
  REGISTRATION_CHECK: 'registrationCheck',
  ID_BELONG_STATE: 'idBelongState',
  STATE: 'state',
  STATES: 'states',
  CHECKED_BIOMETRIC: 'checkedBiometric',
  KDC_BACK_CRED: 'kdc-back-cred',
  ERROR_SECURITY: 'errorSecurity',
  HEALOW_SHOW: 'healowShow',
  VIM: 'vim',
  I18NEXTLNG: 'i18nextLng',
  RESEND_CODE: 'resend-code',
  HANDLE_TELEVISIT: 'handleTelevisit',
  PAYMENT_IN_PROGRESS: 'paymentInProgress',
  HAVE_LOGED: 'haveLoged',
  LOGGED: 'logged',
  NEW_VISIT: 'newVisit',
  INSURANCE_FORM_DATA: 'insuranceFormData',
  REFERRAL: 'referral',
  CURRENT_STATES: 'currentStates',
  SHOW_MODAL: 'showModal',
  RESPONSIBLE_PARTY_DATA: 'ResponsiblePartyData',
  USER_ID_REGISTER: 'userIdRegister',
  BACK: 'back',
  CREATE_PATH: 'createPath',
  ACCOUNT_NUMBER_ID: 'accountNumberId',
  ACCOUNT_NUMBER_USER: 'accountNumberUser',
  REPLACE_ROUTE: 'ReplaceRoute',
  BACK_VERIFICATION: 'backVerification',
  PERSONAL_INFO: 'personalInfo',
  HIDDEN_BACK_BUTTON: 'hiddenBackButton',
  EMAIL: 'email',
  LAST_TIME_STAMP: 'lastTimeStamp',
  IS_PRIMARY: 'isPrimary',
  INSURANCE_NAME: 'insuranceName',
  SHOW_FORM: 'showForm',
  GRECAPTCHA_TOKEN: 'grecaptchatoken',
  CODE: 'code',
};

const LOCAL_STORAGE_ITEMS = {
  LOGGED: 'logged',
  SESSION_FINISHED: 'SESSION_FINISHED'
};

const TEXT_TO_REPLACE = {
  MESSAGE: '[message]',
  SCHEDULE: '[schedule]',
};

const COMPANY ={
  SELFPAY: 'self pay',
};

enum ROOM_STATUS {
	NONACTIVE,
	ACTIVE,
	NONACTIVE_AND_SURVEY_REQUIRED,
	OUT_FROM_REGULAR_SCHEDULE,
	ACTIVE_BUT_COMPLETED,
	NONACTIVE_AND_DISMISSED,
	TROUBLE_WITH_CHAT
  }

export {
	GENDERS,
	TIMES_ZONES,
	SESSION_STORAGE_ITEMS,
	TEXT_TO_REPLACE,
	LOCAL_STORAGE_ITEMS,
	COMPANY,
	SPECIFIC_TIME_ZONES,
	TZ,
	ROOM_STATUS
};
