import { useAppDispatch, useAppSelector } from "adapter/hooks";
import { getMaintenanceInfo } from "adapter/maintenanceAndNotifications/";
import i18n from "app/ui-core/i18n";
import { LOCAL_STORAGE_ITEMS, SESSION_STORAGE_ITEMS, TEXT_TO_REPLACE, TIMES_ZONES } from "app/ui-core/utils/constants";
import { getCustomDate } from "app/ui-core/utils/dates";
import { MaintenanceAndNotificationsResponse } from "domain/useCases/contracts/maintenanceAndNotificationsService";
import ValidationsRepository from "infrastructure/keraltyApi/repositories/validations.repositories";
import { useEffect, useState } from "react";
import useAuth from "./useAuth";
import {useHistory } from 'react-router-dom';

import useUnderMaintenance from "./useUnderMaintenance";
import { closeSymptomChecker } from "adapter/chat/chatSlice";
import { KeraltySWS } from "./useKeraltySWS";


interface ValidationResponse {
	data: MaintenanceAndNotificationsResponse[],
	error: boolean,
}

const useMaintenanceAndNotifications = () => {

	const dispatch = useAppDispatch();
	const [scheduleText, setScheduleText] = useState('');
	const [privateScheduleText, setPrivateScheduleText] = useState('');
	const [serverTime, setServerTime] = useState({
		date: '',
		time: '',
		dateToValidate: new Date(),
	});
	const [notifications, setNotifications] = useState<any[]>([]);
	const [isActivedLoading, setActivedLoading] = useState(false);
	const [modal, setModal] = useState(false);
	const [privateModal, setPrivateModal] = useState(false);
	const [alertClosed, setAlertClosed] = useState(false);
	const { logout } = useAuth();
	const { isUnderMaintenance } = useUnderMaintenance();
	const history = useHistory();

	const [inMaintenance, setInMaintenance] = useState({
		canDisplayModal: false,
		displaiedModal: false,
		displayAlert: false,
	});

	const [displayAlert, setDisplayAlert] = useState(true)

	/**
	 * @returns data: MaintenanceAndNotificationsResponse[], error: boolean
	 */
	const getMaintenanceInformation = async (): Promise<ValidationResponse> => {

		try {

			const data: MaintenanceAndNotificationsResponse[] = await dispatch(getMaintenanceInfo()).unwrap();

			return {
				data: data.filter(data => data.platform === "portal" && data.action === "maintance" && data.status),
				error: false,
			};

		} catch (error) {
			setActivedLoading(false);
			return {
				data: [],
				error: true,
			};
		}


	}

	const getMaintenanceAndNotification = async () => {

		try {

			const info = await getMaintenanceInformation();
			const { date, time } = await ValidationsRepository.timeValidation('')

			const notifications = info.data.map(data => {

				const dateInitET = getCustomDate({ date: data.payload.date_init, language: i18n.language, timeZone: TIMES_ZONES.ET });
				const dateEndET = getCustomDate({ date: data.payload.date_end, language: i18n.language, timeZone: TIMES_ZONES.ET });

				const dateInitCT = getCustomDate({ date: data.payload.date_init, language: i18n.language, timeZone: TIMES_ZONES.CT });
				const dateEndCT = getCustomDate({ date: data.payload.date_end, language: i18n.language, timeZone: TIMES_ZONES.CT });

				const textMessage = `${dateInitET.date} ${dateInitET.time} / ${dateInitCT.time} ${i18n.language === 'es' ? 'al' : 'to'} ${dateEndET.date} ${dateEndET.time} / ${dateEndCT.time}`;
				setScheduleText(textMessage)

				const privateTextMessage = `${dateInitET.time} - ${dateInitCT.time}`;
				setPrivateScheduleText(privateTextMessage)

				const serverTimeET = getCustomDate({ date: `${date}T${time}Z`, language: i18n.language, timeZone: TIMES_ZONES.ET });
				setServerTime({
					...serverTimeET,
					dateToValidate: new Date(serverTimeET.dateToValidate)
				})

				return {
					...data,
					message: {
						...data.message,
						[`body_${i18n.language}`]: data.message[`body_${i18n.language}`].replace(TEXT_TO_REPLACE.MESSAGE, `<span>${textMessage}</span>`),
					},
					status: !(new Date(serverTimeET.dateToValidate) > new Date(dateInitET.dateToValidate)),
					dateInitET,
					dateEndET
				}
			})

			setNotifications(notifications)

		} catch (error) {
			setActivedLoading(false);
		}

	}

	useEffect(() => {
		setActivedLoading(true);
		getMaintenanceAndNotification()

		return () => clearInterval(usersInQueueSetInterval);
	}, [])

	/**
	 * interval
	 */

	let usersInQueueSetInterval: NodeJS.Timer;

	useEffect(() => {
		clearInterval(usersInQueueSetInterval)

		KeraltySWS?.swInstance?.deleteItem(SESSION_STORAGE_ITEMS.UNDER_MAINTENANCE_MODAL)

		if (notifications.length > 0 && new Date(notifications[0].dateInitET.dateToValidate) < serverTime.dateToValidate && serverTime.dateToValidate < new Date(notifications[0].dateEndET.dateToValidate)) {
			setModal(true);
		}

		usersInQueueSetInterval = setInterval(() => {

			//   console.log({
			//   	dateInitET: notifications.length > 0 ? new Date(notifications[0].dateInitET.dateToValidate) : null,
			//   	dateEndET: notifications.length > 0 ? new Date(notifications[0].dateEndET.dateToValidate) : null,
			//   	dateToValidate: serverTime.dateToValidate
			//    })

			setServerTime((time) => {
				return {
					...time,
					dateToValidate: new Date(serverTime.dateToValidate.setSeconds(serverTime.dateToValidate.getSeconds() + 1))
				}
			})

			if (notifications.length > 0 && new Date(notifications[0].dateInitET.dateToValidate) < serverTime.dateToValidate && serverTime.dateToValidate < new Date(notifications[0].dateEndET.dateToValidate)) {
				// mostrar modal
				setInMaintenance({
					...inMaintenance,
					canDisplayModal: true,
					displayAlert: false
				});

				if (KeraltySWS?.swInstance?.getItem(SESSION_STORAGE_ITEMS.LOGGED)) {
					setPrivateModal(false);
					logout();

					dispatch(closeSymptomChecker());
				}

				if (KeraltySWS?.swInstance?.getItem(SESSION_STORAGE_ITEMS.VIM)) {
					KeraltySWS?.swInstance?.deleteItem(SESSION_STORAGE_ITEMS.VIM);
					window.location.reload();
					return
				}

				// Close Symptom Chat
				dispatch(closeSymptomChecker());
				setModal(true);
				if (KeraltySWS?.swInstance?.getItem(SESSION_STORAGE_ITEMS.LOGGED)) {
					KeraltySWS?.swInstance?.deleteItem(SESSION_STORAGE_ITEMS.LOGGED);
					history.push(`/home`);
					setTimeout(() => {
					   window.location.reload();
					}, 300);
				}
			}

			if (notifications.length === 0 || notifications.length > 0 && serverTime.dateToValidate > new Date(notifications[0].dateEndET.dateToValidate)) {
				clearInterval(usersInQueueSetInterval)
				setInMaintenance({
					...inMaintenance,
					canDisplayModal: false
				});

			}

			if (notifications.length > 0 && new Date(notifications[0].dateInitET.dateToValidate) > serverTime.dateToValidate) {

				setInMaintenance({
					...inMaintenance,
					displayAlert: true
				})

				// descomentar para habilitar el modo privado
				//   if(localStorage.getItem(LOCAL_STORAGE_ITEMS.LOGGED) && !sessionStorage.getItem(SESSION_STORAGE_ITEMS.IS_PRIVATE)) {
				//     setPrivateModal(true);
				//   }

				setDisplayAlert(false)
			}

			setActivedLoading(false);

			const paths = ['register', 'forgot-password', 'unblock-account', 'legal', 'patient-registration']
			paths.forEach(path => {
				window.location.pathname.includes(path) && isUnderMaintenance()
			})

		}, 1000)

		return () => clearInterval(usersInQueueSetInterval)
	}, [notifications])

	// descomentar para habilitar el modo privado
	// useEffect(() => {
	// 	if( localStorage.getItem(LOCAL_STORAGE_ITEMS.LOGGED)  && !privateModal ) 
	// 		sessionStorage.setItem(SESSION_STORAGE_ITEMS.IS_PRIVATE, 'true')
	// }, [privateModal])



	const closeAlert = () => {
		setAlertClosed(true)
	}

	useEffect(() => {
		KeraltySWS?.swInstance?.setItem(SESSION_STORAGE_ITEMS.IN_MAINTENANCE, JSON.stringify(inMaintenance))
	}, [inMaintenance]);


	useEffect(() => {
		if (!KeraltySWS?.swInstance?.getItem(SESSION_STORAGE_ITEMS.UNDER_MAINTENANCE_MODAL)) return;

		const maintenance = JSON.parse(KeraltySWS?.swInstance?.getItem(SESSION_STORAGE_ITEMS.UNDER_MAINTENANCE_MODAL) ?? '')

		if (maintenance?.displaiedModal) setModal(true)

		return () => KeraltySWS?.swInstance?.deleteItem(SESSION_STORAGE_ITEMS.UNDER_MAINTENANCE_MODAL)
	}, [KeraltySWS?.swInstance?.getItem(SESSION_STORAGE_ITEMS.UNDER_MAINTENANCE_MODAL)])

	return {
		getMaintenanceInformation,
		notifications,
		isActivedLoading,
		scheduleText,
		inMaintenance,
		modal,
		privateModal,
		privateScheduleText,
		setPrivateModal,
		closeAlert,
		alertClosed
	}

}

export default useMaintenanceAndNotifications;